/*
* TODO:
* ako ima više od tri decimale, unormali rem
*/

.header-container {
  width: 100%;
  position: absolute;
}

.banner {
  display: flex;
  padding: 15px 20px;
  color: var(--color-white);
  background-color: var(--color-primary);
  font-size: 0.9rem;
  align-items: center;
}

.banner__action {
  margin-left: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  color: var(--color-border-grey);
}

.banner__action:hover {
  color: var(--color-white);
}

.banner {
  justify-content: space-between;
}

.banner__content {
  max-width: 15rem;
}

.banner--is-visible .page {
  grid-template-rows: 8.75rem auto;
}

.banner--is-visible .sidebar {
  top: 8.75rem;
}

.banner__close {
  height: 20px;
  cursor: pointer;
  fill: var(--color-border-grey);
}

.banner__close:hover {
  fill: var(--color-white);
}

@media screen and (min-width: 600px) {
  .banner__content {
    max-width: 100%;
  }

  .banner--is-visible .sidebar {
    top: 7.75rem;
    height: calc(100vh - var(--size-header) - 2.75rem);
  }

  .banner--is-visible .page {
    grid-template-rows: 7.85rem auto;
  }
}

@media screen and (min-width: 992px) {
  .banner--is-visible .header {
    top: 46px;
  }
}

.header {
  grid-area: header;
  display: flex;
  width: 100%;
  padding: 1.25rem;
  border-bottom: 2px solid var(--color-border-grey);
  position: fixed;
  z-index: 5;
  background: var(--color-white);
}

.header__logo {
  height: 2.25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
}

.header__logo > img {
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 100%;
}

.header-profile {
  display: inline-flex;
  margin-left: auto;
  align-items: center;
  cursor: pointer;
}

.profile-avatar {
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  box-sizing: content-box;
}

.profile-avatar.header-avatar {
  height: 1.625rem;
  width: 1.625rem;
  padding: 0.3125rem;
}

.profile-avatar.show-active::after {
  content: '';
  position: absolute;
  height: 0.5rem;
  width: 0.5rem;
  box-sizing: content-box;
  border: 0.1875rem solid var(--color-white);
  background: linear-gradient(180deg, #b673c9 0%, var(--color-primary) 100%);
  top: 0;
  right: 0;
  border-radius: 50%;
}

.profile-avatar__image {
  border-radius: 50%;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
}

.profile__name {
  margin-left: 0.625rem;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.3125rem;
  display: none;
}

.profile-dropdown__icon {
  margin-left: 0.625rem;
  display: flex;
  align-items: center;
}

.profile-dropdown__icon::before {
  border: solid var(--color-lavender-gray);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 0.1875rem;
  content: '';
  transform: rotate(45deg) translateY(-0.1875rem);
}

.profile-dropdown__icon[data-expanded='true']::before {
  transform: rotate(-135deg) translateY(0px) translateX(-0.1875rem);
}

.header-menu__icon {
  display: inline-flex;
  align-items: center;
  margin-right: 0.625rem;
}

.header-menu__icon > svg {
  height: 1.5625rem;
  width: 1.5625rem;
  fill: var(--color-text-grey);
}

.profile-dropdown {
  opacity: 0;
  position: absolute;
  z-index: 1;
  padding: 1.5rem 1.25rem;
  border-radius: calc(var(--size-border-radius) * 2);
  max-width: 16.25rem;
  width: 100%;
  background-color: var(--color-white);
  border: 1px solid var(--color-border-grey);
  box-shadow: 0 1px 3px 0 var(--color-anti-flash-white);
  top: calc(var(--size-header) - 0.625rem);
  right: 0.625rem;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  transform: translate3d(0%, -2rem, 0);
  opacity: 0;
}

.profile-avatar__hook {
  position: absolute;
  width: 1rem;
  height: 1rem;
  transform: translate3d(55%, calc(1.875rem - 2rem), 0) rotate(45deg);
  background: var(--color-white);
  border-top: 1px solid var(--color-border-grey);
  border-left: 1px solid var(--color-border-grey);
  animation: profile-dropdown-animation 0.5s ease-in-out;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.profile-dropdown--is-expanded {
  pointer-events: all;
  transform: translate3d(0%, 0%, 0);
  opacity: 1;
}

.profile-dropdown--is-expanded + .header-profile .profile-avatar__hook {
  opacity: 1;
  transform: translate3d(55%, 2rem, 0) rotate(45deg);
}

.profile-dropdown__admin {
  padding: 0.375rem 1.375rem;
  border-radius: 0.625rem;
  background-color: rgba(127, 63, 152, 0.1);
  color: var(--color-primary);
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-align: center;
  margin-bottom: 0.75rem;
}

.profile-dropdown__divider {
  border: 1px solid var(--color-border-grey);
  margin: 0.75rem 0;
}

.profile-dropdown__logout {
  color: var(--color-dim-grey);
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.3125rem;
  display: flex;
  transition: all 0.2s ease-in-out;
}

.profile-dropdown__logout:hover {
  opacity: 0.8;
}

.profile-dropdown__logout > svg {
  height: 1.3125rem;
  width: 1.3125rem;
  object-fit: contain;
  fill: var(--color-deep-amethyst);
  margin-right: 0.75rem;
}

.profile-dropdown__plan {
  display: flex;
  align-items: center;
}

.profile-dropdown__plan-name {
  color: var(--color-white);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 21px;
  text-align: center;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: var(--color-green);
  margin-right: 8px;
  display: inline-block;
}

.profile-dropdown__plan-period {
  color: var(--color-dark-purple);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.profile-dropdown__user {
  margin-bottom: 12px;
}

.profile-dropdown__user-plan {
  display: flex;
}

.profile-dropdown__user .profile-avatar__hook {
  display: none;
}

.profile-dropdown__user-info {
  padding-left: 6px;
  flex: auto;
}

.profile-dropdown__user-name {
  color: var(--color-dark-purple);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.profile-dropdown__user-plan-name {
  color: var(--color-text-dark-grey);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
}

.profile-dropdown__user-email {
  color: var(--color-text-dark-grey);
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
}

.profile-dropdown__upgrade {
  color: var(--color-white);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding: 12px 8px;
  display: block;
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 12px;
  background-color: var(--color-green);
  transition: background-color 0.2s ease-in-out;
}

.profile-dropdown__upgrade:hover {
  background-color: var(--color-green-hover);
}

@media screen and (min-width: 992px) {
  .header-menu__icon {
    display: none;
  }

  .header__logo {
    position: relative;
    transform: none;
    top: auto;
    left: auto;
  }

  .profile__name {
    display: inline-block;
  }

  .header {
    padding: 1.375rem 2.5rem;
  }

  .header__logo > img {
    transform: translate3d(0, -50%, 0);
  }

  .profile-dropdown {
    right: 20px;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--color-white);
    z-index: 3;
  }
}

.banner {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 5;
  margin: 0 auto;
  width: 100%;
}

.banner + .header {
  margin-top: 62px;
}

@media screen and (min-width: 600px) {
  .banner + .header {
    margin-top: 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 992px) {
  .banner + .header {
    margin-top: 46px;
  }
}

@media screen and (min-width: 1920px) {
  .header {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    max-width: 1400px;
    width: 93.5%;
  }

  .header-container {
    width: 100%;
  }

  .banner {
    max-width: 1400px;
  }
}
