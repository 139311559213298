/*
* TODO:
* WRITE ME!
*/

.sidebar {
  display: none;
  top: calc(var(--size-header) - 2px);
  z-index: 4;
  width: 100%;
  background-color: var(--color-white);
  border-bottom: 5px solid var(--color-border-grey);
  overflow-y: auto;
}

@media screen and (max-width: 992px) {
  .sidebar.expanded {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
  }
}

.account-limits {
  display: flex;
  justify-content: space-between;
}

.sidebar-plan-details > .account-limits {
  flex-direction: column;
}

.resource-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text-dark-grey);
  font-size: 0.8rem;
  line-height: 1.3125rem;
}

.resource-info.disabled {
  color: var(--color-text-grey-disabled);
}

.resource-info > svg {
  height: 12px;
  width: 12px;
  fill: var(--color-text-grey-disabled);
}

.navigation-menu__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: var(--color-text-grey);
  line-height: 1.3;
  font-weight: 500;
  background-color: white;
  font-size: 1rem;
  padding: 1rem 1.25rem;

  transition: all 0.1s ease-in-out;
}

.navigation-menu__link:hover {
  background-color: var(--color-snow);
}

.navigation-menu__link.active {
  color: var(--color-black-text);
  background-color: var(--color-snow);
}

.navigation-menu-link__icon {
  height: 20px;
  width: 20px;
  margin-right: 0.625rem;
  fill: var(--color-text-grey);
}

.navigation-menu-link__icon > svg {
  height: 20px;
  width: auto;
}

.navigation-menu__link.active > .navigation-menu-link__icon {
  fill: var(--color-purple);
}

.sidebar-plan-details {
  display: none;
}

.sidebar-plan-details .account-resource {
  margin-bottom: 0.625rem;
}

.sidebar-plan-details .account-resource:last-child {
  margin-bottom: 0;
}

.navigation-menu__item--is-divider {
  display: none;
}

@media screen and (min-width: 992px) {
  .sidebar {
    grid-area: sidebar;
    position: fixed;
    top: calc(var(--size-header) + 2px);
    left: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;

    height: calc(100vh - var(--size-header));

    width: 300px;
    padding: 1.125rem 2.5rem 2.5rem;
    border-right: 2px solid var(--color-border-grey);
    border-bottom: none;
  }

  .navigation {
    flex: auto;
    margin: 0 -1rem 1.5rem;
    overflow-y: auto;
    /* (item height + outer margin) * N of items */
    max-height: calc((30px + 8px) * 9);
  }

  /* width */
  .navigation::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .navigation::-webkit-scrollbar-track {
    background: var(--color-sidebar-track);
    border-radius: 5px;
  }

  /* Handle */
  .navigation::-webkit-scrollbar-thumb {
    background: var(--color-sidebar-thumb);
    border-radius: 5px;
  }

  /* Handle on hover */
  .navigation::-webkit-scrollbar-thumb:hover {
    background: var(--color-sidebar-thumb-hover);
  }

  .navigation-menu__item {
    margin-bottom: 0.375rem;
  }

  .navigation-menu__link {
    border-radius: var(--size-border-radius);
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
  }

  .navigation-menu__link > svg {
    margin-right: 10px;
  }

  .sidebar-plan-details {
    flex: 0;
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .plan-row {
    display: flex;
    justify-content: space-around;
    align-items: center;

    padding: 0.5rem 0.75rem;
    border-radius: var(--size-border-radius);
    border: 1px solid var(--color-border-grey);
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;
  }

  .plan-row .upgrade {
    flex: 1;
    text-align: center;
  }

  .plan-row:hover {
    box-shadow: 5px 5px 15px 2px var(--color-border-grey);
  }

  .plan-row > span {
    font-weight: 500;
  }

  .current-plan {
    padding: 0.15rem 0.3rem;
    border-radius: var(--size-border-radius);

    background-color: var(--color-green);
    color: white;
    font-size: 0.625rem;
    text-transform: uppercase;
  }

  .navigation-menu__item--is-divider {
    color: var(--color-old-lavender);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1;
    text-transform: uppercase;
    font-size: 0.75rem;
    display: block;
    padding: 1rem 1rem 0;
  }
}

@media screen and (min-width: 1400px) {
  .sidebar {
    padding: 30px;
  }

  .navigation {
    height: 40%;
  }

  .sidebar-plan-details {
    max-height: 250px;
  }
}

@media screen and (min-width: 1920px) {
  .sidebar {
    position: static;
    inset: auto;
    height: calc(100vh - var(--size-header));
  }
}
