.pagination-container {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.pagination {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 0 auto;
}

.pagination-left-arrow {
  margin-right: 1.5rem;
}

.pagination-right-arrow {
  margin-left: 15px;
}

.pagination svg {
  width: .5625rem;
  fill: var(--color-dark-purple);
  cursor: pointer;
}

.pagination svg:hover {
  fill: var(--color-purple);
}

.pagination-left-arrow.disabled svg,
.pagination-left-arrow.disabled svg:hover,
.pagination-right-arrow.disabled svg,
.pagination-right-arrow.disabled svg:hover {
  fill: var(--color-text-grey);
  cursor: not-allowed;
}

.pagination-item {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color-dark-purple);
  margin-right: 0.625rem;
  padding: 0.75rem 1.0625rem;
  border-radius: 5px;
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.pagination-item:last-child {
  margin-right: 0;
}

.pagination-item:hover {
  background-color: var(--color-light-purple);
}

.pagination-item.active {
  color: var(--color-purple);
  background-color: var(--color-light-purple);
}

.pagination-item.dots {
  cursor: default;
}

.pagination-item.dots:hover {
  background-color: white;
}

.pagination-size.input-wrapper {
  margin-bottom: 0;
}

.pagination-size select {
  width: 100px;
  margin-left: 2.375rem;
  background-color: white;
  min-width: 8.75rem;
}

.pagination-size {
  display: none;
}

.pagination-size > .text-input {
  cursor: pointer;
  transition: all .2s ease-in-out;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}

.pagination-size:hover > .text-input {
  opacity: .8;
}

@media screen and (min-width: 992px) {
  .pagination {
    overflow-x: unset;
  }

  .pagination-size {
    display: flex;
  }
}