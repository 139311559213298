/*
* TODO:
* WRITE ME!
*/

.notification-list {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 10;
  inset: 16px 16px auto 16px;
}

.notification {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.125rem;
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  border: 1px solid var(--notification-style);
  color: var(--notification-style);
  fill: var(--notification-style);
  background-color: var(--color-white);
  cursor: pointer;
  margin-bottom: 16px;
  margin-left: auto;
  width: 100%;
  animation: notification-animation 0.2s;
  position: relative;
}

/*
* Description:
* [1] Property rgba(var(--[name]), [value]) does not work well
* in all browsers at the time of writing.
*
* [2] Opacity based background-colors of fixed elements have really bad visibility
* in reality (since application is white)
*
* Therefore, the main element has solid white color and pseudo element "before"
* is used for shading the background.
*/

.notification::before {
  content: '';
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.075;
  background-color: var(--notification-style);
}

.notification--type-is-success {
  --notification-style: var(--color-green);
}

.notification--type-is-warning {
  --notification-style: var(--color-orange);
}

.notification--type-is-error {
  --notification-style: var(--color-red);
}

.notification__content {
  padding-left: 16px;
  padding-right: 16px;
  flex: auto;
}

.notification__close-icon {
  z-index: 1;
}

.notification__description {
  font-weight: 400;
  margin-top: 8px;
}

@keyframes notification-animation {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@media screen and (min-width: 768px) {
  .notification {
    width: 560px;
    left: auto;
    padding: 20px 24px;
  }
}
