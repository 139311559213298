.table-wrapper {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border-radius: calc(var(--size-border-radius) * 2);
}

.page-heading.level-1 + .table-wrapper .table-header,
.templates__filters-row + .table-wrapper .table-header {
  position: sticky;
  top: 0;
}

.page-heading.level-1 + .table-wrapper + .button-row,
.page-heading.level-1 + .table-wrapper + .pagination-container,
.teams-resources + .table-wrapper + .button-row,
.templates-container + .button-row {
  position: sticky;
  bottom: 0;
  background-color: var(--color-white);
  padding: 2rem 0;
  margin: 0 0 -2rem 0;
}

.table {
  width: 100%;
  border-spacing: 0;
}

.table-header {
  color: var(--color-dark-purple);
  background-color: var(--color-snow);
  border-radius: var(--size-border-radius);
  margin-bottom: 0.5rem;
  z-index: 1;
}

.table-body {
}

.table-row {
  transition: var(--transition-ease);
  cursor: pointer;
}

.table-row.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.table--is-templates th:nth-child(5),
.table--is-templates td:nth-child(5) {
  text-align: center;
}

.table--is-templates .table-row:hover,
.table-row.is-selected {
  background-color: rgba(0, 0, 0, 0.015);
}

.table-header .table-column {
  border-bottom: none;
  font-weight: 500;
  color: var(--color-dark-purple);
}
.table-row:last-child .table-column {
  border-bottom: none;
}

.table-column--is-destroy {
  fill: var(--color-light-silver);
  transition: all 0.2s ease-in-out;
  cursor: initial;
}

.table-row .table-column--is-destroy {
  fill: var(--color-text-grey);
}

.table-column--is-destroy:hover svg {
  cursor: pointer;
}

.table-column--is-destroy svg:hover {
  opacity: 0.6;
}

.table-column {
  font-weight: 400;
  text-align: left;
  line-height: 1.3125rem;
  color: var(--color-old-lavender);
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--color-border-grey);
}

.table-column.center {
  text-align: center;
}

.table--is-team-accounts {
  padding-bottom: 2rem;
}

.table--is-team-accounts .table-row {
  cursor: initial;
}

.table--is-invoices {
}

.table--is-domains {
}

.table--is-custom-template .table-column:first-child,
.table--is-templates .table-column:first-child,
.table--is-templates .table-column:last-child {
  width: 4rem;
}

.table--is-connection-editors .table-row {
  cursor: auto;
}

.table--is-connection-editors .table-column:first-child {
  width: 10rem;
}

.table--is-connection-editors .table-column:nth-child(2) {
  width: 4rem;
}

.table--is-connection-editors .table-column:last-child {
  display: flex;
  justify-content: flex-end;
}

.page--is-team .table-wrapper[data-length='0'] {
  min-height: 5rem;
}

.page--is-templates .table-wrapper[data-length='0'] {
  min-height: 0;
}

.table__spinner-container {
  border-bottom: 0;
}

.table__spinner-container td {
  padding: 40px;
}
