.teams-resources {
  padding: 20px 30px;
  border-radius: 10px;
  border: 1px solid var(--color-border-grey);
  margin-bottom: 1.5rem;
}

.teams-resources .title {
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.teams-resources .account-limits {
  width: 100%;
  flex-direction: column;
}

.teams-resources .account-resource {
  width: 100%;
  margin-bottom: 10px;
}

.teams-resources .account-resource .questionmark-tooltip-icon {
  height: 13px;
  width: 13px;
  margin-left: 5px;
}

.teams-resources .account-resource:last-child {
  margin-bottom: 0;
}

.managed-accounts-table {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}

.managed-accounts-table .table-rows {
  max-height: 30vh;
}

.managed-accounts-table-header,
.managed-account-table-row {
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 2fr 2fr 0.5fr;
  padding: 10px 20px;
}

.managed-account-table-row {
  border-bottom: 1px solid var(--color-border-grey);
  color: var(--color-text-grey);
}

.managed-accounts-table .column {
  width: auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.column.center {
  justify-content: center;
}

.unlimited-resource {
  width: 100%;
  padding: 10px;
  font-size: 0.7rem;
  color: var(--color-text-grey);

  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
}

.table--is-team-accounts .table-column.remove-child svg {
  height: 21px;
  width: 21px;
  fill: var(--color-text-grey);
  opacity: 0.6;
}

.table--is-team-accounts .table-column.remove-child svg:hover {
  opacity: 1;
}

@media screen and (min-width: 1300px) {
  .teams-resources .account-limits {
    flex-direction: row;
  }

  .teams-resources .account-resource {
    width: calc(20% - 30px);
  }
}

/*COUNTER INPUT */
.team-resource-number-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20px;
  margin: 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.team-resource-number-input.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.team-resource-number-input.processing {
  opacity: 0.6;
}

.team-resource-number-input .team-resource-number-input__top,
.team-resource-number-input .team-resource-number-input__bot {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.team-resource-number-input.disabled .team-resource-number-input__top,
.team-resource-number-input.disabled .team-resource-number-input__bot,
.team-resource-number-input.disabled > div > svg:hover {
  cursor: not-allowed;
  fill: var(--color-text-grey);
}

.team-resource-number-input.processing .team-resource-number-input__top,
.team-resource-number-input.processing .team-resource-number-input__bot,
.team-resource-number-input.processing > div > svg:hover {
  cursor: progress;
  fill: var(--color-text-grey);
}

.team-resource-number-input .team-resource-number-input__top svg,
.team-resource-number-input .team-resource-number-input__bot svg {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  fill: var(--color-text-grey);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.team-resource-number-input .team-resource-number-input__top svg:hover,
.team-resource-number-input .team-resource-number-input__bot svg:hover {
  fill: rgba(117, 117, 117, 0.5);
}
.team-resource-number-input .team-resource-number-input__bot svg {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.team-resource-number-input .team-resource-number-input__mid {
  text-align: center;
  font-size: 14px;
  color: var(--color-text-grey);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.remove-child svg {
  cursor: pointer;
}
