.billing-info-cards-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.billing-info-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 1.125rem 2rem 1.5rem;
  border-radius: calc(var(--size-border-radius) * 2);
  border: 1px solid var(--color-border-grey);
  margin-bottom: 1rem;
}

.billing-info-card:last-child {
  margin-bottom: 0px;
}

.billing-info-card .title {
  color: var(--color-text-grey);
  color: var(--color-old-lavender);
  font-size: 14px;
  line-height: 20px;
}

.billing-info-card .content {
  color: var(--color-dark-purple);
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  margin: 1rem 0;
}

.billing-info-card .content img {
  height: 1.5rem;
}

.billing-info-card .action {
  font-size: 0.7rem;
  color: var(--color-purple);
  cursor: pointer;
}

.billing-info-card .action:hover {
  color: var(--color-green);
}

.current-plan-info {
  display: flex;
  flex-direction: column;
}

.current-plan-info .general {
  display: flex;
  align-items: center;

  font-size: 0.7rem;
  font-weight: 500;
}

.current-plan-info .general img {
  height: 1.875rem;
  width: 1.875rem;
  margin-right: 1rem;
}

.current-plan-info .plan-name {
  text-transform: uppercase;

  color: var(--color-purple);
  background-color: var(--color-light-purple);

  padding: 2px 8px;
  border-radius: 4px;
  background-color: rgba(127, 63, 152, 0.1);

  font-size: 0.6875rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.65;
  text-align: center;
  margin-right: 0.75rem;
  transform: translateY(1px);
}

.plan-description {
  color: var(--color-dark-purple);
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
}

.current-plan-info .benefits {
  display: block;
  align-items: center;
  margin-top: 1rem;
}

.plan-benefit {
  margin-bottom: 0.5rem;
}

.plan-benefit:last-child {
  margin-bottom: 0;
}

.current-plan-info .plan-benefit {
  display: flex;
  align-items: center;

  font-size: 0.875rem;
  margin-right: 1.25rem;
}

.current-plan-info .plan-benefit:last-child {
  margin-right: 0px;
}

.current-plan-info .plan-benefit img {
  height: 1.3125rem;
  width: 1.3125rem;
  margin-right: 0.5rem;
}

.current-plan-info .actions {
  margin-top: 20px;
}

.current-plan-info .actions .button:first-child {
  margin-right: 20px;
}

.subscription-status {
  display: block;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 1rem 2rem 1.5rem;
  border: 1px solid var(--color-border-grey);
  border-radius: 10px;
  background-color: var(--color-white);
  color: var(--color-old-lavender);
  line-height: 1;
  font-size: 0.8125rem;
  margin-top: 2rem;
}

.subscription-status > .tab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 0.8125rem;
  color: var(--color-text-grey);
  margin-bottom: 1rem;
}

.subscription-status > .tab:last-child {
  margin-bottom: 0;
}

.subscription-status .title {
  color: var(--color-dark-purple);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 0.375rem;
}

.payment-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  border: 1px solid var(--color-border-grey);
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 1rem 2rem 1.5rem;
  color: var(--color-old-lavender);
  line-height: 1;
  font-size: 0.8125rem;
}

.billing-information-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: var(--color-text-grey);
  margin-bottom: 20px;
  line-height: 1.25rem;
}

.billing-information-container .title,
.current-payment-method-container .title {
  color: var(--color-dark-purple);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 0.375rem;
  display: block;
}

.current-payment-method-container {
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
}

.current-payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--color-border-grey-hover);
  border-radius: 5px;
  border: 1px solid var(--color-border-grey);

  padding: 5px 10px;
}

.current-payment-method img {
  height: 25px;
  width: 25px;
}

.expiration-date {
  color: var(--color-text-grey);
}

.edit-billing-info {
  color: var(--color-purple);
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}

.edit-billing-info:hover {
  color: var(--color-dark-purple);
}

.invoices-table {
  margin: 0;
  max-width: 48rem;
}

.invoice > .status > span {
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 500;

  width: auto;
  border-radius: 5px;

  color: var(--color-red);
  background-color: var(--color-light-red);

  min-width: 4rem;
  text-align: center;
  display: inline-block;

  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 21px;
}

.invoice > .status > span.paid {
  color: var(--color-green);
  background-color: var(--color-light-green);
}

.invoice svg {
  fill: var(--color-purple);
  height: 15px;
  width: 15px;

  cursor: pointer;
}

.invoice svg:hover {
  fill: var(--color-dark-purple);
}

.update-payment-method {
  display: flex;
  flex-direction: column;
}

.update-payment-method .actions .button:first-child {
  margin-right: 20px;
}

.accepted-credit-cards {
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;
  width: 200px;
}

.accepted-credit-cards img {
  width: calc(140px / 5);
}

.update-payment-method .credit-card-info > div {
  width: 100%;
}

.update-payment-method .recurly-element {
  margin-top: 0;
  width: 100%;

  height: 40px;
  background-color: var(--color-input);
  border-radius: 5px;
}

.view-payment-history {
  display: inline-block;
  margin-top: 25px;
  font-size: 0.8rem;
  color: var(--color-purple);
  cursor: pointer;
}

.view-payment-history:hover {
  opacity: 0.7;
}

@media screen and (min-width: 400px) {
  .invoice > .column.status > span {
  }
}

@media screen and (min-width: 500px) {
  .subscription-status > .tab {
    width: 33.3%;
  }
}

@media screen and (min-width: 600px) {
  .payment-information {
    flex-direction: row;
    justify-content: space-between;
  }

  .payment-information > div {
    margin-bottom: 0;
  }

  .current-plan-info .benefits {
    display: flex;
  }

  .subscription-status {
    display: flex;
  }

  .subscription-status > .tab {
    margin-bottom: 0;
  }

  .plan-benefit {
    margin-bottom: 0;
  }

  .update-payment-method .credit-card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .update-payment-method .credit-card-row > div {
    width: calc(50% - 15px);
  }
}

@media screen and (min-width: 620px) {
  .invoices-table {
  }

  .invoice > .column.status > span {
  }
}

@media screen and (min-width: 730px) {
  .billing-info-cards-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .billing-info-card {
    width: calc(33.3% - 10px);
    margin-right: 15px;
    margin-bottom: 0px;
  }

  .billing-info-card:last-child {
    margin-right: 0px;
  }

  .current-plan-info {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .current-plan-info .plan-benefit {
    font-size: 0.8rem;
  }

  .current-plan-info .actions {
    margin-top: 0px;
  }
}

@media screen and (min-width: 992px) {
  .billing-info-cards-container {
    flex-direction: column;
  }

  .billing-info-card {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .billing-info-card:last-child {
    margin-bottom: 0px;
  }

  .current-plan-info {
    flex-direction: column;
  }

  .current-plan-info .actions {
    margin-top: 30px;
  }

  .invoices-table {
  }

  .update-payment-method .credit-card-row {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width: 1050px) {
  .billing-info-cards-container {
    flex-direction: row;
  }

  .billing-info-card {
    width: calc(33.3% - 10px);
    margin-right: 15px;
    margin-bottom: 0px;
  }

  .billing-info-card:last-child {
    margin-right: 0px;
  }

  .current-plan-info {
    flex-direction: row;
  }

  .current-plan-info .actions {
    margin-top: 0px;
  }
}
