.options-menu {
  z-index: 2;
  position: absolute;
  top: 1rem;
  right: 0;
  width: 12rem;
  transform: translate3d(calc(-0.5rem), 0, 0);
  border: 1px solid var(--color-light-silver); /* TODO: variable */
  border-radius: calc(var(--size-border-radius) * 2);
  background-color: var(--color-white);
  box-shadow: 4px 6px 16px 0 rgba(144, 119, 153, 0.17);
  padding: 0.625rem 0;
}

.options-menu[hidden] {
  display: none;
}

.options-menu__item {
    padding: 0.3125rem 1.25rem;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    color: var(--color-old-lavender); /* TODO: variable */
    font-size: 0.875rem;
    line-height: 1rem;
    user-select: none;
}

.options-menu__item.disabled,
.options-menu__item.disabled:hover {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: white;
}

.options-menu__item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.options-menu__separator {
  border-top: 1px solid var(--color-light-silver);
  margin: 0.3125rem 0;
}

.options-menu-item__icon {
  margin-right: 0.625rem;
  max-width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.options-menu-item__icon > svg {
  width: 100%;
  object-fit: contain;
  fill: var(--color-old-lavender); /* TODO: variable */
}

@media screen and (min-width: 576px) and (max-width: 1199px) {
  .card-grid__item:not(:nth-child(2n)) .options-menu {
    transform: translate3d(calc(100% - 1rem), 0, 0);
  }
}

@media screen and (min-width: 1200px) {
  .card-grid__item:not(:nth-child(3n)) .options-menu {
    transform: translate3d(calc(100% - 1rem), 0, 0);
  }
}

/*

.card-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
}

@media screen and (min-width: 576px) {
    .card-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1200px) {
    .card-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

 */
