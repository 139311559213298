/*
* TODO:
* WRITE ME!
*/

.templates-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.templates__list--is-empty {
  width: 100%;
  padding-bottom: 40px;
  /*
  margin-bottom: 1.5rem;
  border-bottom: 0.1px solid var(--color-border-grey);
  */
  margin-top: 2rem;

  display: flex;
  justify-content: center;
}

.templates__list--is-empty > img {
  width: 20%;
  min-width: 10rem;
}

.templates__filters-row {
  width: 100%;
  margin-bottom: 30px;
}

.templates__list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  border-bottom: 1px solid var(--color-border-grey);
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.template__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: var(--color-card-grey);
  border-radius: calc(var(--size-border-radius) * 2);
  border: 2px solid white;
  padding: 1rem 1rem 0.8125rem;
  height: 7rem;

  cursor: pointer;
}

.template__card:hover {
  background-color: var(--color-border-grey-hover);
}

.template__card--is-selected {
  background-color: var(--color-light-green);
  border-color: var(--color-green);
}

.template__card--is-selected:hover {
  background-color: var(--color-light-green);
  border-color: var(--color-green);
}

.template__logo {
  max-height: 3.75rem;
  height: 100%;
  margin-bottom: 0.625rem;
}

.template__name {
  text-align: center;
  font-size: 0.7rem;
}

.templates-list--is-empty {
  max-width: 30vw;
  margin: 2rem auto 3rem;
}

.templates-list--is-empty > img {
  max-width: 100%;
}

.creating-template {
  height: 78px;
  opacity: 0.5;
  cursor: progress;
}

.creating-template .purple-highlight {
  font-weight: 500;
}

@media screen and (min-width: 450px) {
  .templates__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 600px) {
  .templates__list {
    grid-template-columns: repeat(4, 1fr);
  }

  .templates__filters-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 750px) {
  .templates__list {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .templates__list {
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 2.5rem;
    margin-bottom: 1.875rem;
  }

  .templates-list--is-empty {
    max-width: 15vw;
  }
}

@media screen and (min-width: 1000px) {
  .templates__list {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 1100px) {
  .templates__list {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .templates__list {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (min-width: 1400px) {
  .templates__list {
    grid-template-columns: repeat(8, 1fr);
  }
}
