/*
* TODO:
* update to new invision design
* transitions
* make it variable manageable
* update var naming
* organize me a bit
*/

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.button {
  outline: none;
  border-radius: var(--size-border-radius);
  box-shadow: 0 3px 3px 0 var(--color-anti-flash-white);
  min-width: calc(var(--size-dimension-base) * 10);
  text-align: center;
  padding: calc(var(--size-dimension-base) * 1.25)
    calc(var(--size-dimension-base) * 2.75);
  cursor: pointer;
  transition: var(--transition-ease);
  border: 1px solid var(--color-light-silver);
  background-color: var(--color-white);
  font-family: 'Galano-Grotesque', sans-serif;
  font-weight: 500;
  position: relative;
}

.button:hover {
  background-color: rgb(245, 245, 245);
}

.button[disabled],
.button[disabled='true'],
.button--state-disabled,
.button--state-disabled:hover {
  cursor: not-allowed;
  opacity: 0.5;
}

.button--type-block {
  display: block;
  width: 100%;
}

.button--type-success,
.button--type-success.button--state-disabled:hover {
  border: 1px solid var(--color-green);
  background-color: var(--color-green);
  color: var(--color-white);
}

.button--type-success:focus,
.button--type-success:hover {
  border-color: var(--color-green-hover);
  background-color: var(--color-green-hover);
}

.button--type-primary,
.button--type-primary.button--state-disabled:hover {
  border: 1px solid var(--color-primary);
  background-color: var(--color-white);
  color: var(--color-primary);
}

.button--type-primary:hover {
  background-color: rgba(127, 63, 152, 0.1);
}

.button--type-danger,
.button--type-danger.button--state-disabled:hover {
  border: 1px solid var(--color-red);
  background-color: var(--color-white);
  color: var(--color-red);
}

.button--type-danger:hover {
  background-color: rgba(242, 22, 25, 0.1);
}

.button__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  border-radius: var(--size-border-radius);
  background-color: inherit;
  display: flex;
  align-content: center;
  justify-content: center;
}

.button--type-loading {
  pointer-events: none;
}

.button .spinner.button-spinner-white path {
  fill: var(--color-primary);
}

.button--type-success .spinner.button-spinner-white path,
.button--type-primary .spinner.button-spinner-white path {
  fill: var(--color-white);
}

.button--type-danger .spinner.button-spinner-white path {
  fill: var(--color-red);
}
