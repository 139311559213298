.tooltip-wrapper {
  position: relative;
  cursor: pointer;
  pointer-events: none;
}

.tooltip-wrapper > * {
  pointer-events: all;
}

.tooltip-wrapper::before {
  opacity: 0;
  pointer-events: none;
  content: '';
  position: absolute;
  border: 1px solid var(--color-light-silver);
  background-color: var(--color-white);
  box-shadow: 4px 6px 16px 0 rgba(144, 119, 153, 0.17);
  height: 0.5rem;
  width: 0.5rem;
  z-index: 0;
  transition: all 0.1s ease-in-out;
  bottom: calc(100% + 0.75rem);
  transform: translate3d(-50%, 50%, 0) rotate(45deg);
  left: 50%;
}

.tooltip-wrapper::after {
  position: absolute;
  inset: -0.1rem;
  content: '';
}

.tooltip-wrapper:hover::before {
  opacity: 1;
}

.tooltip-wrapper:hover .tooltip {
  opacity: 1;
}

.tooltip {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  border: 1px solid var(--color-light-silver);
  background-color: var(--color-white);
  box-shadow: 4px 6px 16px 0 rgba(144, 119, 153, 0.17);
  padding: 0.5rem 1rem;
  border-radius: var(--size-border-radius);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  width: 8.5rem;
  transition: all 0.1s ease-in-out;
  z-index: 2;
  top: auto;
  bottom: calc(100% + 0.75rem);
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  color: var(--color-text-dark-grey);
  font-weight: 500;
}

.tooltip-wrapper--is-short > .tooltip {
  width: auto;
}

.tooltip-wrapper--is-long > .tooltip {
  width: 10rem;
}

.tooltip-wrapper--position-left .tooltip {
  right: calc(100% + 0.75rem);
  left: auto;
  transform: translate3d(0, 50%, 0);
  bottom: 50%;
}

.tooltip-wrapper--position-left::before {
  top: auto;
  right: auto;
  left: -0.75rem;
  bottom: 50%;
  transform: translate3d(-50%, 50%, 0) rotate(45deg);
}

.tooltip-wrapper--position-right .tooltip {
  top: auto;
  right: auto;
  bottom: 50%;
  transform: translate3d(0, 50%, 0);
  left: calc(100% + 0.25rem);
}

.tooltip-wrapper--position-right::before {
  left: calc(100% + 0.25rem);
  bottom: 50%;
  transform: translate3d(-50%, 50%, 0) rotate(45deg);
  right: auto;
}
