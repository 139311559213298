/*
* TODO:
* - scan project for undefined colors in hex format.
* - reorganize or/and rename colors if need-be
* - missing root font size definition
* - missing base X dimension scaling
* - update marked lines
* - check invision and project colors in hex
*/

:root {
  --size-dimension-base: 8px;
  --size-font-base: calc(var(--size-dimension-base) * 2);
  --size-header: calc(var(--size-dimension-base) * 10);
  --size-border-radius: calc(var(--size-dimension-base) / 2);
  --size-sidebar: calc(var(--size-dimension-base) * 37.5);

  --font-primary-family: 'Galano-Grotesque', sans-serif;
  --font-secondary-family: var(--font-primary-family);

  --shadow-static: 0px; /* <= box-shadow */
  --shadow-hover: 0px; /* <= box-shadow */

  --transition-ease: 0.25s ease-in-out;

  --color-input-border: var(--color-light-silver); /* <= general specific */

  --color-primary: var(--color-purple); /* <= var(--color-primary) */
  --color-secondary: #000; /* <= #0BCE9A */
  --color-purple: #7f3f98;
  --color-light-purple: #efe6f5;
  --color-dark-purple: #1f0330;
  --color-black-text: #171617;
  --color-border-grey: #e9e4ea;
  --color-border-grey-hover: rgba(119, 70, 174, 0.1);
  --color-border-grey-shadow: rgba(119, 70, 174, 0.2);
  --color-text-grey: #756c7a;
  --color-text-dark-grey: #737376;
  --color-text-grey-disabled: #c1bcc3;
  --color-green: #0bce9a;
  /* --color-green: #068766; good contrast*/
  --color-light-green: rgba(11, 206, 154, 0.15);
  --color-green-hover: #09ae82;
  --color-red: #f2165f;
  --color-light-red: rgba(242, 22, 95, 0.1);
  /* --color-red: #de0c52;
  --color-light-red: rgba(242, 22, 95, 0.05); good contrast*/
  --color-orange: #f59f26;
  --color-white: #fff;
  --color-snow: #f8f6f9;
  --color-input: #f8f6f9;
  --color-card-grey: #f5f5f5;
  --color-old-lavender: #746b7a;
  --color-anti-flash-white: #f2f2f2;
  --color-light-silver: #d9d3db;
  --color-deep-amethyst: #a092a3;
  --color-bright-gray: #ebe2f2;
  --color-dim-grey: #6e6573;
  --color-lavender-gray: #c7c5c9;

  --color-brand-twitter: #2ba4ef;
  --color-brand-facebook: #1978f1;
  --color-brand-email: #e74437;

  --color-sidebar-track: #f1f1f1;
  --color-sidebar-thumb: #bbb;
  --color-sidebar-thumb-hover: #999;
}
