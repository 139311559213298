/*
* TODO:
* WRITE ME!
*/

.refer-page-container {
  max-width: 38.75rem;
  margin: 0 auto;
  width: 100%;
  /* padding: 20px 0 40px 0; */
}

.referral-steps {
  max-width: 43.125rem;
  width: 100%;
  margin: 0 auto;
}

.referral-steps,
.referral-step-card {
  display: flex;
  flex-direction: column;
}

.referral-step-card {
  align-items: flex-start;
  padding: 1.875rem;
  border: 1px solid var(--color-border-grey);
  border-radius: calc(var(--size-border-radius) * 2);
  background-color: var(--color-white);
  box-shadow: 0 3px 3px 0 var(--color-anti-flash-white);
  /* margin: 0 1rem 1rem; */
  margin-bottom: 1rem;
}

.referral-step-card:last-child {
  margin-bottom: 0px;
}

.referral-step-card .step {
  margin-bottom: 0.375rem;
  color: var(--color-dark-purple);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  margin-top: -0.1875rem;
}

.referral-step-card .description {
  color: var(--color-old-lavender);
}

.referral-credits {
  margin-top: 0.75rem;
  text-align: center;
  color: var(--color-old-lavender);
}

.referral-spacer {
  border: 1px solid var(--color-border-grey);
  margin: 2rem 0;
}

.referral-title.level-1 {
  margin-top: 2rem;
  color: var(--color-dark-purple);
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.375;
}

.referral-title.level-2 {
  margin-top: 1.3125rem;
  color: var(--color-old-lavender);
  line-height: 1.375;
  text-align: center;
}

.referral-title.level-3 {
  margin-bottom: 0.75rem;
  color: var(--color-dark-purple);
  font-weight: 500;
  margin-top: 2rem;
}

.share-referral-link {
  margin-top: 2rem;
}

.share-options {
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-options > a {
  text-decoration: none;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: var(--size-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.333rem;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.share-options > a:hover {
  opacity: 0.8;
}

.share-options > a:nth-child(1) {
  background-color: var(--color-brand-email);
}

.share-options > a:nth-child(2) {
  background-color: var(--color-brand-facebook);
}

.share-options > a:nth-child(3) {
  background-color: var(--color-brand-twitter);
}

.share-options > a:nth-child(3) > svg {
  height: 2rem;
}

.share-options > a > svg {
  height: 1.25rem;
  width: auto;
}

.referral-title {
  text-align: center;
}

@media screen and (min-width: 600px) {
  .referral-steps {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .referral-steps::after {
    content: '';
    clear: both;
    display: table;
  }

  .referral-step-card {
    margin-right: 1rem;
    width: calc(33.33% - 0.75rem);
    float: left;
    margin-bottom: 0;
  }

  .referral-step-card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .referral-title.level-1 {
    margin-top: 3rem;
  }

  .referral-title.level-2 {
    margin-top: 1.3125rem;
  }

  .referral-spacer {
    margin-top: 3.75rem;
    margin-bottom: 1.875rem;
  }

  .referral-tracking {
    margin-bottom: 1.875rem;
  }
}
