/*
* TODO:
* WRITE ME!
*/

/* TOGGLE SWITCH */
.toggle-switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 36px; /* 4 + 14 + 14 + 4*/
  height: 18px; /* 2 + 14 + 2*/
  min-width: 36px;
  min-height: 18px;
  cursor: pointer;
}

.toggle-switch.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.toggle-switch.loading {
  opacity: 0.6;
  cursor: progress;
}

.destroyed .toggle-switch {
  cursor: not-allowed;
}

.toggle-switch .label {
  position: absolute;
  left: 45px;
  width: max-content;
  color: var(--color-text-dark-grey);
  font-weight: 500;
}

/* Edit default HTML checkbox */
.toggle-switch input {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  appearance: none;
  transform: translate3d(-4px, -3px, 0);
  border-radius: 20px;
}

/* Slider background */
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-text-grey-disabled);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* Slider unchecked toggle */
.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* Slider checked toggle */
input:checked + .slider {
  background-color: var(--color-light-purple);
}

/* Slider checked toggle */
input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
  background-color: var(--color-purple);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (min-width: 1400px) {
  .toggle-switch {
    width: 44px; /* 5 + 16 + 16 + 5*/
    height: 22px; /* 3 + 16 + 3 */
  }

  .toggle-switch .label {
    position: absolute;
    left: 55px;
    width: max-content;
    color: var(--color-text-dark-grey);
  }

  .slider:before {
    height: 16px;
    width: 16px;
    left: 5px;
    bottom: 3px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background-color: var(--color-purple);
  }
}
