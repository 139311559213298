.hide {
  display: none !important;
}

.purple-highlight {
  color: var(--color-purple);
}

.scroll-margin-top {
  scroll-margin-top: 150px;
}

img:-moz-loading {
  visibility: hidden;
}
