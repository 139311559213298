.spinner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner > svg {
  height: 1rem;
  width: auto;
  fill: var(--color-purple);
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spinner--size-cover {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: var(--color-white);
  z-index: 2;
}

.spinner--size-cover > svg {
  height: 2rem;
}

.spinner--size-medium > svg {
  height: 2rem;
}

.component__spinner-container {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.component__spinner-container.is-connected-accounts {
  margin-bottom: -10px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
