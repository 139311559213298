/*
* TODO:
* WRITE ME!
*/

.truncate {
  --line-cut: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: var(--line-cut);
  -webkit-box-orient: vertical;
  word-wrap: anywhere;
  word-break: break-all;
}
