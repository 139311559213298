.card {
  box-sizing: border-box;
  border: 1px solid var(--color-light-silver);
  border-radius: calc(var(--size-border-radius) * 3);
  background-color: var(--color-white);
  box-shadow: 0 3px 3px 0 var(--color-anti-flash-white);
  padding: 1rem;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  box-shadow: 5px 5px 15px 2px var(--color-border-grey);
}

.card--is-disabled {
}

.card-header {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.share-header {
  align-items: center;
}

.card-title {
  color: var(--color-dark-purple);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  flex: auto;
  padding-right: 0.5rem;
}

.card-extras {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.card-options {
  height: 1.75rem;
  width: 1.75rem;
  border-radius: var(--size-border-radius);
  background-color: var(--color-anti-flash-white);
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.card-options:last-child {
  margin-right: 0;
}

.card-options > svg {
  width: 1rem;
  height: auto;
  fill: var(--color-old-lavender);
}

/*
* TODO: implement me
*/
.card-options--type-pin--is-active {
  background-color: var(--color-bright-gray);
}

.card-options--type-pin > svg {
  width: 100%;
  fill: var(--color-primary);
}

.card-button {
}

.card-button > svg {
  height: 0.625rem;
  margin-right: 0.5rem;
  width: auto;
  fill: var(--color-white);
}

.card-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: minmax(0, 1fr);
}

/*
* TODO: temp code
*/
.share-card {
  min-height: 13.285rem;
  display: flex;
  flex-direction: column;
}

.share-card .button {
  margin-top: auto;
}

@media screen and (min-width: 576px) {
  .card-grid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .card-grid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}
