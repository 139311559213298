/*
* TODO:
* WRITE ME!
*/

/* BIG TEXT INPUT */
.big-text-input {
  border: none;
  outline: none;

  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-text-grey);

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 500px) {
  .big-text-input {
    font-size: 2.5rem;
    font-weight: 600;
  }
}

/* CUSTOM TEXT INPUT */
.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  margin-bottom: 20px;
}

.input-wrapper.disabled {
  opacity: 0.6;
}

.collapsed-input {
  font-size: 1rem;
}

.input-wrapper.bottom-margin {
  margin-bottom: 40px;
}

.input-label {
  font-size: 0.7rem;
  color: var(--color-text-grey);
  margin-bottom: 10px;
  color: var(--color-old-lavender);
  font-family: 'Galano-Grotesque', sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 0.625rem;
}

.input-label.required::after {
  content: '  *';
  color: var(--color-red);
}

.text-input {
  font-family: 'Galano-Grotesque', sans-serif;
  height: 40px;
  width: 100%;
  padding: 10px;
  background-color: var(--color-input);

  font-size: 0.8rem;

  border-radius: 5px;
  border: 1px solid var(--color-input-border);
  outline: none;
}

.text-input.white {
  background-color: var(--color-white);
}

textarea.text-input {
  resize: vertical;
  font-family: 'Galano-Grotesque', sans-serif;
  min-height: 120px;
  max-height: 160px;
}

.input-wrapper.disabled .text-input {
  cursor: not-allowed;
}

/* The container */
.radio-input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  cursor: pointer;
  height: 0.875rem;
  width: 0.875rem;
  display: inline-flex;
}

.disabled .radio-input,
.disabled .radio-input__field {
  cursor: not-allowed;
}

.radio-input__field {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  border: 0;
}

.radio-input__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.875rem;
  width: 0.875rem;
  border: 2px solid var(--color-light-silver);
  border-radius: 50%;
  background-color: var(--color-white);
}

.radio-input__field:checked ~ .radio-input__checkmark {
  border-color: var(--color-primary);
}

.radio-input__field:checked ~ .radio-input__checkmark:after {
  display: block;
  background-color: var(--color-primary);
  border-color: var(--color-white);
}

.radio-input__checkmark:after {
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  width: 0.35rem;
  height: 0.35rem;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 2px solid transparent;
}
