.checkout {

}
.checkout .quantity-picker {
    display: flex;
    align-items: flex-end;
}
.checkout .quantity-picker > span {
    height: 28px;
    width: 28px;
    background-color: #f2f0f6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #764593;
    border-radius: 50%;
    font-weight: 600;
    line-height: 1;
    user-select: none;
    cursor: pointer;
}
.checkout .quantity-picker > span > span {
    transform: translate3d(0, -1px, 0);
}
.checkout .quantity-picker > input {
    padding: 4px 0;
    text-align: center;
    font-weight: 600;
    color: #764593;
    border: 1px solid #bbbcbf;
    border-radius: 3px;
    appearance: none;
    background: none;
    margin-left: 24px;
    margin-right: 24px;
    width: 36px;
    font-size: 12px;
}
.checkout .quantity-picker > input::-webkit-outer-spin-button, .checkout .quantity-picker > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.checkout .quantity-picker > input[type=number] {
    -moz-appearance: textfield;
}
