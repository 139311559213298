/*
* Notice: Legacy code (from old dashboard)
*/

.checkout-page {
  width: 100%;
}

.checkout-page .container {
  width: 950px;
  padding: 0 16px;
}

.checkout .checkout__header {
  display: flex;
  align-items: center;
  margin-bottom: -0.3rem;
}
.checkout .checkout-header__logo {
  display: inline-flex;
  align-items: center;
}

.checkout .checkout-header__logo > span {
  transform: translate3d(-15px, -5px, 0);
  font-size: 1.2rem;
  line-height: 1;
}
.checkout .checkout-header__logo > img {
  display: inline-block;
  max-width: 100%;
  height: 100px;
  margin-left: -30px;
  transform: translate3d(0px, -5px, 0);
}
.checkout .checkout-header__login {
  flex: auto;
  text-align: right;
  font-size: 0.9rem;
  transform: translate3d(0, -5px, 0);
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  position: relative;
}
.checkout .checkout-header__login a {
  color: rgba(127, 63, 152, 1);
  font-weight: 600;
  margin-left: 0.5rem;
}
.checkout .checkout__breadcrumb {
  border-bottom: 1px solid #e8e8e9;
  display: flex;
  list-style-type: none;
  margin-bottom: 1.3rem;
  filter: grayscale(100%) blur(1px); /*DEPRECATE*/
}
.checkout .checkout__breadcrumb-item {
  padding-bottom: 0.4rem;
  font-weight: 500;
  margin-right: 1.6rem;
  cursor: not-allowed; /*DEPRECATE*/
  transition: color 0.25s ease-in-out;
}
.checkout .checkout__breadcrumb-item[data-active='true'] {
  border-bottom: 2px solid #383c43;
  color: #383c43;
}
.checkout .order-section {
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;
  border-bottom: 1px solid #b9babd;
}
.checkout .order-section:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.checkout .order-title {
  color: #44474e;
  padding-bottom: 0.4rem;
  margin-bottom: 18px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  margin-top: 4px;
}
.checkout .checkout-footer__bottom a {
  margin: 0 1rem;
}
.checkout .step-2-title.payment-header__title {
  margin-bottom: 20px;
}
.checkout .step-two-footer {
  margin: 0 auto;
  position: sticky;
  bottom: 0;
  padding-bottom: 24px;
  padding-top: 24px;
  background-color: #f8f8f8;
}

.checkout-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
}

.checkout-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 660px;
  width: 100%;
  background-color: var(--color-white);
  transform: translate3d(-50%, -50%, 0);
  border-radius: 20px;
}

.checkout-modal__body {
  padding: 46px;
  padding-bottom: 28px;
  text-align: center;
}

.checkout-modal__footer {
  background-color: #f5f6f8;
  color: rgba(127, 63, 152, 1);
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.checkout-modal__footer div:last-of-type {
  font-size: 16px;
  font-weight: 600;
}

.checkout-modal__close {
  position: absolute;
  top: 10px;
  right: 24px;
  font-size: 40px;
  line-height: 1;
}

.checkout-modal__close > img {
  width: 16px;
  display: inline-block;
  cursor: pointer;
}

.checkout-modal__title {
  font-size: 38px;
  margin-bottom: 16px;
  font-weight: 500;
  line-height: 1.2;
}

.checkout-modal__description {
  font-size: 18px;
  margin-bottom: 32px;
  line-height: 1.2;
}

.checkout .checkout-modal .checkout-button {
  margin: 0;
}

.checkout .checkout-button.ghost {
  background: none;
  border: none;
  color: rgb(127, 63, 152);
  margin-top: 12px;
}

.checkout .applied-coupon {
  display: flex;
  flex-direction: column;
}

.checkout .coupon-success {
  display: flex;
  align-items: center;
  color: rgb(39, 190, 86);
  font-size: 14px;
  line-height: 16px;
}

.checkout .coupon-success > img {
  margin-right: 6px;
  width: 16px;
}

.checkout .coupon-badge {
  display: inline-block;
  align-items: center;
  padding: 3px 12px;
  border-radius: 15px;
  background-color: rgb(232, 232, 233);
  color: rgb(138, 140, 145);
  margin-left: 12px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  width: max-content;
  margin-top: 10px;
}

.checkout .coupon-badge > img {
  width: 14px;
  display: inline-block;
  margin-left: 12px;
}

.checkout .coupon-success > img {
}

.checkout .coupon {
}

.checkout .coupon-button.inactive {
  color: #b9babd;
  background-color: #e8e8e9;
  border-color: #e8e8e9;
  cursor: not-allowed;
}

.checkout .coupon-row {
  display: flex;
}

.checkout .checkout-button {
  min-width: 82px;
  text-align: center;
}

/*.checkout .checkout-button .loader {*/
/*  display: none;*/
/*}*/

.checkout .checkout-button:not(.loading) .loader {
  display: none;
}

.checkout .checkout-button.loading span:not(.loader) {
  display: none;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.loader {
  margin: 0px auto;
  font-size: 10px;
  margin-bottom: -2px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid var(--color-white);
  display: inline-block;
  /*-webkit-transform: translateZ(0);*/
  /*-ms-transform: translateZ(0);*/
  /*transform: translateZ(0);*/
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.checkout .checkout-button.loading span.spinner {
  display: inline-block;
}

.checkout .coupon-row input {
  font-family: 'Galano-Grotesque', sans-serif !important;
}

.checkout .coupon-button {
  margin: 0 0 0 12px !important;
}

.checkout .coupon-button > span:not(.loader) {
  margin: 0;
  transform: translateY(-1px);
  display: inline-block;
}

.checkout .payment-information__saved {
}

.checkout .payment-information-saved__field {
  margin-bottom: 12px;
  font-size: 14px;
}

.checkout .payment-information-saved__field label {
  font-weight: 500;
}

@media screen and (max-width: 576px) {
  .checkout-page .container {
    width: 100%;
  }

  .checkout .payment-header__badge {
    display: none !important;
  }

  .checkout .payment-method__card:not(:last-child) {
    margin: 0;
  }

  .checkout .payment-method__card {
    border-radius: 0 !important;
  }

  .checkout .payment-method__card:first-child,
  .checkout
    .payment-method__card:first-child[style*='none']
    + .payment-method__card {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
  }

  .checkout .payment-method__card:last-child {
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }

  .checkout .billing-description.checkout-footer__bottom > a {
    margin: 0.5rem 0;
    display: block;
  }
}
/*html::before {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  pointer-events: none;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  background-position: top center;*/
/*  background-image: url('./FIRST_TWO.png');*/
/*  background-image: url('./FIRST_SCREEN.png');*/
/*  content: '';*/
/*  opacity: 0.2;*/
/*  !*background-position: 0 166px;*!*/
/*}*/

/**/

.checkout .plan-period {
  padding: 6px;
  border-radius: 6px;
  display: inline-flex;
  margin: 0 auto;
  background-color: rgba(119, 70, 174, 0.05);
}
.checkout .plan-period__item {
  border-radius: 6px;
  margin-right: 8px;
  color: var(--color-text-dark-grey);
  cursor: not-allowed; /*DEPRECATE*/
  transition: background-color 0.2s ease-in-out;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 500;
}
/* .checkout .plan-period__item[data-active='false']:hover {
   background-color: rgba(255, 255, 255, 0.4);
} */  /*DEPRECATE*/
.checkout .plan-period__item:last-child {
  margin-right: 0;
}
.checkout .plan-period__item[data-active='true'] {
  color: #774693;
  background-color: var(--color-white);
}
.checkout .step-one__header {
  display: flex;
  justify-content: center;
}
.checkout .step-one-header__money-back {
  margin-left: auto;
}
.checkout .step-one-header__description h3 {
  text-align: center;
  color: #44474e;
  line-height: 1.5;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 1rem 0 2rem 0;/*DEPRECATE*/
  padding: 0;
}
.checkout .step-one-header__description ul {
  margin-top: 0.4rem;
}
.checkout .step-one-header__description li {
  margin-bottom: 0.2rem;
  color: #44474e;
  font-size: calc(13px + (100vw - 320px) / 880);
  line-height: 1.5;
  /* display: flex;
  align-items: center; *//*DEPRECATE*/
}
.checkout .step-one-header__description img {
  width: 12px;
  margin-right: 0.6rem;
}
.checkout .plan-period__wrapper {
  text-align: center;
  margin: 44px auto;
  filter: grayscale(100%) blur(1px); /*DEPRECATE*/
}
.checkout .step-one__team {
  max-width: 800px;
  text-align: center;
  cursor: not-allowed !important; /*DEPRECATE*/
  filter: grayscale(100%) blur(1.5px); /*DEPRECATE*/
}
.checkout .step-one-team__title {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  letter-spacing: 0.6px;
}
.checkout .step-one-team__icon {
  max-width: 42px;
  display: flex;
  align-items: center;
}
.checkout .step-one-team__icon > img {
  width: 100%;
  object-fit: contain;
}
.checkout .team__description {
  margin-top: 16px;
  text-align: center;
}
.checkout .step-one-team__options {
  display: flex;
  justify-content: center;
  margin: 32px auto 62px;
  margin-left: -80px;
  margin-right: -80px;
  margin-top: 52px;
  text-align: left;
}
.checkout .step-one-team__option.disabled {
  cursor: default;
  opacity: 0.7;
}
.checkout .step-one-team__options .summary-holder {
  width: 100%;
}
.checkout .step-one-team__option {
  max-width: 250px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  margin: 0 16px;
  background: var(--color-white);
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.05),
    0 1px 3px 0 rgba(56, 60, 67, 0.15);
  transition-duration: 0.25s;
  cursor: not-allowed; /*DEPRECATE*/
  border: 1px solid transparent;
}
/* .checkout .step-one-team__option:hover {
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.07),
    0 8px 14px 1px rgba(56, 60, 67, 0.15);
} *//*DEPRECATE*/
/* .checkout .step-one-team__option.disabled:hover {
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.05),
    0 1px 3px 0 rgba(56, 60, 67, 0.15);
} *//*DEPRECATE*/
.checkout .step-one-team__option[data-active='true'] {
  border-color: rgba(127, 63, 152, 1);
}
.checkout
  .step-one-team__option[data-active='true']
  .payment-method-card__radio {
  background-color: rgba(127, 63, 152, 1);
  border-color: rgba(127, 63, 152, 1);
}
.checkout .step-one-team__option[data-active='true'] .step-one-team__name {
  color: rgba(127, 63, 152, 1);
}
.checkout .step-one-team__name {
  flex: auto;
  text-align: center;
  font-weight: 600;
  transform: translate3d(0, -2px, 0);
  font-size: 1.4rem;
}
.checkout .payment-plans {
  /*max-width: 800px;*/
  /*margin: 0 auto;*/
  display: flex;
  justify-content: space-between;
}
.checkout .payment-plans-wrapper {
  margin-bottom: 56px;
  margin-left: -28px;
  margin-right: -28px;
  cursor: not-allowed !important; /*DEPRECATE*/
  filter: grayscale(100%); /*DEPRECATE*/
}
.checkout .payment-plans-wrapper > div {
  text-align: center;
}
.checkout .step-one-footer {
  margin: 0 auto 0;
  position: sticky;
  bottom: 0;
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #f8f8f8;
}
.checkout .step-one-team__description {
  margin-top: 6px;
  max-width: 600px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  max-width: 480px;
}

@media screen and (max-width: 576px) {
  .checkout .payment-plans-wrapper {
    margin: 0;
    padding: 0;
  }

  .checkout .payment-plans {
    display: block;
  }

  .checkout .step-one-team__options {
    margin: 0;
    padding: 0;
    margin-top: 12px;
  }

  .checkout .addon-picker-footer {
    background: none;
    position: relative;
    padding: 0;
    display: block;
  }

  .checkout .addon-picker-footer > div {
    display: block !important;
  }

  .checkout .addon-picker-footer.is-sticky::before {
    content: none !important;
  }

  .checkout .plan-period__wrapper {
    margin: 0.5rem 0 1.5rem 0;
  }

  .checkout .step-one-team__option {
    flex-direction: column-reverse;
    padding-top: 108px;
  }

  .checkout .step-one-team__icon {
    margin-bottom: 12px;
  }

  .checkout .step-one-team__option .payment-method-card__radio {
    display: none;
  }

  .checkout .step-one__team {
    margin-top: 12px;
  }
}
.checkout--step.step-3 {
  margin-bottom: 32px;
}

.checkout .payment-header {
  display: flex;
  align-items: first baseline;
  margin-bottom: 18px;
}
.checkout .payment-header__title {
  margin: 0;
  padding: 0;
  color: #44474e;
  line-height: 1.5;
  font-weight: 500;
  font-size: 16px;
}
.checkout .payment-header__badge {
  display: inline-flex;
  align-items: center;
  line-height: 1.3;
  font-weight: 700;
  color: #383c43;
  margin-left: auto;
  font-size: 1rem;
}
.checkout .payment-header__badge > img {
  height: 50px;
  margin-right: 0.6rem;
}
.checkout .payment-form {
  border-radius: 3px;
  padding: 14px 16px;
  background-color: var(--color-white);
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.05),
    0 1px 3px 0 rgba(56, 60, 67, 0.15);
  margin-bottom: 24px;
}
.checkout .payment-method__cards {
  position: sticky;
  top: 20px;
}
.checkout .payment-method__card {
  border-radius: 3px;
  margin: 0 0 0.8rem;
  padding: 10px;
  border: 1px solid transparent;
  background-color: var(--color-white);
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.05),
    0 1px 3px 0 rgba(56, 60, 67, 0.15);
  transition-duration: 0.25s;
  cursor: pointer;
}
.checkout .payment-method__card:hover {
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.07),
    0 8px 14px 1px rgba(56, 60, 67, 0.15);
}
.checkout .payment-method__card[data-active='true'] {
  border-color: rgba(127, 63, 152, 1);
}
.checkout .payment-method-card__text {
  color: #383c43;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
}
.checkout .payment-method-card__radio {
  min-width: 16px;
  height: 16px;
  margin-right: 0.8rem;
  transition: background-color 0.25s ease-out, border-color 0.5s ease-out;
  position: relative;
  border-radius: 50%;
  border: 1px solid #b9babd;
  background-color: var(--color-white);
}
.checkout
  .payment-method__card[data-active='true']
  .payment-method-card__radio {
  background-color: rgba(127, 63, 152, 1);
  border-color: rgba(127, 63, 152, 1);
}
.checkout .payment-method-card__radio::after {
  content: '';
  position: absolute;
  background-color: var(--color-white);
  background-color: rgba(255, 255, 255, 1);
  width: 7px;
  height: 7px;
  left: 50%;
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s ease-out;
}
.checkout .payment-method__card[data-active='true'] {
  border-color: rgba(127, 63, 152, 1);
}
.checkout .payment-method-card__icon {
  max-height: 14px;
  display: inline-block;
  height: 100%;
  margin-right: 4px;
}
.checkout .order-total {
  padding-top: 1.6rem;
  font-size: calc(15px + (200vw - 640px) / 880);
  line-height: 1.5;
  font-weight: 500;
  color: #383c43;
  display: flex;
  flex-direction: column;
}
.checkout .total {
  display: flex;
  padding-bottom: 1.6rem;
  align-items: center;
}

.checkout .order-total__no-refund {
  font-size: 12px;
  padding: 2px 5px;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: #ffe4e8;
  border-radius: 0.5rem;
  font-weight: 500;
}

.checkout .order-total__refund {
  font-size: 12px;
  padding: 2px 5px;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 25px;
  background-color: #d6ffe9;
  border-radius: 0.5rem;
  font-weight: 500;
}

.checkout .order-total__title {
  font-size: 14px;
  padding-top: 6px;
}
.checkout .order-total + .checkout-action {
  margin-top: -1.2rem;
  display: table;
}
.checkout .order-total__price {
  margin-left: auto;
  flex: auto;
  text-align: right;
}
.checkout .checkout-action {
  font-size: calc(11px + (100vw - 320px) / 880);
  text-decoration: underline;
  color: #8a8c91;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
  margin-top: 0.4rem;
  display: inline-block;
}

.step3-confirm-payment--wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 6px;
}

.step3-confirm-payment--wrapper > button {
  margin: 0 !important;
}

.step3-confirm-payment--wrapper > button[disabled] {
  background-color: rgba(127, 63, 152, 0.6);
  border: 1px solid white;
}

button.billing-checkout-button[disabled] {
  background-color: rgba(127, 63, 152, 0.6);
  border: 1px solid white;
}

.payment-method__disabled {
  pointer-events: none;
  opacity: 0.5;
}

button#pay-with-amazon {
  margin-top: 6px;
  margin-bottom: 26px;
}

button.pay-now-disabled,
button.pay-now-disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

@media screen and (max-width: 576px) {
  .checkout .payment-method__cards {
    position: relative;
    top: 0;
    margin-bottom: 0.8rem !important;
  }

  .checkout .payment-method__cards .payment-method__card {
    margin-bottom: 0 !important;
  }

  .checkout .order-total__refund {
    width: 100%;
  }

  .checkout .coupon-value {
    margin-top: 10px;
  }

  .checkout .checkout--step.step-3 .total[style*='flex'] {
    display: block !important;
  }

  .payment-method__cards [data-balloon]::before,
  .payment-method__cards [data-balloon]::after {
    left: auto;
    right: 0px;
  }
}
.checkout .billing-information-field {
  margin-bottom: 18px;
}
.checkout .billing-information-field label {
  display: block;
  margin-bottom: 0.4rem;
  line-height: 1.5;
  color: #8a8c91;
  font-size: calc(11px + (100vw - 320px) / 880);
}
.checkout .billing-information-field input,
.checkout .billing-information-field select,
.checkout .recurly-element {
  height: 42px;
  transition: border-color 0.15s ease-in-out;
  color: #44474e;
  padding: 0.8rem;
  outline: 0;
  line-height: 1.5;
  border: 1px solid #b9babd;
  border-radius: 3px;
  width: 100%;
  width: 100%;
  color: black;
  font-family: Helvetica;
}
.checkout .billing-information-field .input-error {
  margin-top: 10px;
  font-size: 12px;
  color: #f64f64;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.checkout .billing-information-field:not(.error) .input-error {
  display: none;
}
.checkout .billing-information-field .input-error div {
  margin-right: 8px;
}
.checkout .billing-information-field .input-error div > img {
  width: 16px;
  height: 16px;
  display: inline-block;
  transform: translateY(-1px);
}
.checkout .billing-information-field.error input,
.checkout .billing-information-field.error select,
.checkout .recurly-element-invalid {
  border-color: #f64f64;
}
.checkout .billing-information-field input {
  appearance: none;
}
.checkout .recurly-element {
  transform: translateY(-4px);
}
.checkout .recurly-element-focus {
  border-color: rgba(127, 63, 152, 1);
}
.checkout .billing-information-field input:focus {
  border-color: rgba(127, 63, 152, 1);
}
.checkout .checkout-button {
  appearance: none;
  background-color: rgba(127, 63, 152, 1);
  background-image: none;
  border: 1px solid rgba(127, 63, 152, 1);
  border-radius: 3px;
  box-sizing: border-box;
  color: var(--color-white);
  cursor: pointer;
  line-height: 1.5;
  margin: 0;
  position: relative;
  text-transform: none;
  touch-action: manipulation;
  transition-duration: 0.25s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  user-select: none;
  vertical-align: bottom;
  font-weight: 600;
  padding: 0.6rem 1.2rem;
  margin-bottom: 26px;
  margin-top: 6px;
}
.checkout .billing-description {
  box-sizing: border-box;
  font-size: 11px;
  color: #8a8c91;
  line-height: 1.5;
  margin-bottom: 12px;
}
.checkout .billing-description:last-of-type {
  margin-bottom: 48px;
}
.checkout .billing-description > a {
  text-decoration: none;
  color: #44474e;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-property: color;
}
.checkout .billing-description > a:hover {
  color: #8a8c91;
}
.checkout .vat-number {
  color: rgba(127, 63, 152, 1);
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 6px;
}
.checkout .billing-security {
  display: flex;
  font-size: 12px;
  padding-right: 80px !important;
  margin-bottom: 8px;
}

.checkout .billing-security-wrapper {
  margin-top: 24px;
}

.checkout .billing-security div {
  max-width: 22px;
  min-width: 24px;
  margin-right: 10px;
  transform: translateY(-2px);
}

.checkout .billing-security:last-child div {
  min-width: 20px;
  margin-right: 12px;
  margin-left: 2px;
}

.checkout .billing-security img {
  width: 100%;
}

.checkout-button {
  transition: all 0.2s ease-in-out !important;
}

.checkout-button:hover {
  opacity: 0.8;
}

.checkout-button > img {
  height: 20px;
  display: inline-block;
  object-fit: contain;
}

.checkout-button > span {
  font-weight: 500;
  margin-right: 4px;
}

/* Special case for "Pay now" buttons being displayed below the payment method */
.checkout-button + .checkout-button {
  margin-top: -12px;
}

.checkout-button .ant-spin {
  display: inline-block;
  position: absolute;
  right: -32px;
  top: 12px;
}

.checkout-button.checkout-button--type-paypal {
  background-color: #0079c1; /* 003087, 009cde */
  border-color: #0079c1;
  display: flex;
}

.checkout-button.checkout-button--type-applepay {
  background-color: #000;
  border-color: #000;
  display: flex;
}

.checkout-button.checkout-button--type-applepay > img {
  height: 16px;
  margin-top: 2px;
  margin-left: 4px;
}

.checkout-button.checkout-button--type-amazon {
  background-color: #333e47;
  border-color: #333e47;
}

.checkout-button.checkout-button--type-amazon > img {
  margin-top: 6px;
}

/* Removing Amazon pay visual */
.checkout-button .amazonpay-button-inner-image {
  display: none !important;
}

.checkout-button.checkout-button--type-venmo {
  background-color: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #3d95ce;
}

.checkout-button.checkout-button--type-venmo > img {
  height: 10px;
}

@media screen and (max-width: 576px) {
  .checkout .billing-description:last-of-type {
    margin-bottom: 24px;
  }

  .checkout .billing-description + .checkout .billing-description {
    margin-top: 24px;
  }

  .checkout-button.coupon-button > span {
    min-width: 0;
  }

  .checkout .order-total__title {
    display: block;
  }
}
.checkout .summary-holder {
  padding-top: 18px;
  padding-bottom: 16px;
  padding-right: 6px;
  border-bottom: 1px solid #e8e8e9;
}
.checkout .summary-holder {
}
.checkout .summary-holder:first-of-type {
  padding-top: 0;
}
.checkout .summary-holder:last-of-type {
  border-bottom: 0;
}
.checkout .summary__recommended {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #efc786;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  font-weight: 600;
  padding: 6px 8px;
}
.checkout .summary__disabled {
  background-color: #b9babd;
}
.checkout .summary__included {
  background-color: #67d088;
  color: #2f5d39;
}
.checkout .summary__disabled .summary__disabled-link {
  text-decoration: underline;
}
.checkout .summary {
  display: flex;
  align-items: flex-start;
  color: #383c43;
  position: relative;
}
.checkout .summary__icon {
  margin-right: 12px;
  max-width: 30px;
  width: 100%;
}
.checkout .order-section .summary__icon {
  margin-right: 12px;
  max-width: 46px;
  width: 100%;
  padding: 0 6px;
}
.checkout .summary__description {
  flex: auto;
  line-height: 1.6;
}
.checkout .summary__icon > img {
  max-width: 100%;
  display: inline-block;
  object-fit: contain;
}
.checkout .summary__details {
  padding-right: 20px;
  display: flex;
  align-content: center;
}
.checkout .summary__content {
  flex: auto;
  align-items: end;
}
.checkout .summary__price {
  margin-left: auto;
  font-weight: 500;
  min-width: 65px;
  display: flex;
  align-self: end;
  justify-content: flex-end;
  padding-right: 0;
  font-size: 16px;
  padding-bottom: 0.5rem;
}
.checkout .summary__title {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
  color: #383c43;
  margin-right: 0.2rem;
  margin-bottom: 0;
  padding-bottom: 0;
}
.checkout .summary__description {
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.5;
  color: #383c43;
}
.checkout .summary__discount {
  margin-bottom: 0.2rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
}
.checkout .summary-discount__badge {
  font-size: 12px;
  color: #f64f64;
  padding: 0.2rem 0.8rem;
  font-weight: 500;
  border-radius: 15px;
  display: inline-block;
  background-color: #ffe4e8;
  margin-right: 0.8rem;
}
.checkout .summary-discount__amount {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: #f64f64;
  font-weight: 500;
  margin-left: auto;
}
.checkout .summary__info {
  display: flex;
}
.checkout .summary-addon-holder {
  padding-bottom: 1.2rem;
}
.checkout .order-section .summary-addon-holder .summary__icon {
  max-width: 24px;
  padding: 0;
}
.checkout .order-section .summary-addon {
  padding-right: 100px;
}
.checkout .summary-addon-holder:first-of-type {
  padding-top: 0;
}
.checkout .summary-addon {
  display: flex;
  align-items: flex-start;
  color: #383c43;
  background-color: #f8f8f8;
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.05),
    0 1px 3px 0 rgba(56, 60, 67, 0.15);
  border-radius: 3px;
  padding: 22px;
  position: relative;
}
.checkout .summary-addon--type-isolated {
  padding: 20px 22px;
  background-color: var(--color-white);
  margin-bottom: 18px;
  position: relative;
  padding-right: 30px;
}
.checkout .summary-addon--type-isolated.summary-addon--disabled {
  opacity: 0.6;
}
.checkout .summary-addon--type-isolated .summary {
  align-items: center;
}
.checkout .summary-addon--type-isolated:first-of-type {
  padding: 20px 22px;
  padding-right: 30px;
}
.checkout .summary-addon--type-isolated .summary__description {
  margin-bottom: 0;
  max-width: 400px;
  width: 100%;
  font-size: 11px;
}
.checkout .summary-addon--type-isolated .summary__info {
  margin-left: auto;
  margin-right: 40px;
}
.checkout .summary-addon--type-isolated .summary__info .summary-addon-price {
  display: flex;
  align-items: center;
}
.checkout .summary-addon--type-isolated .summary {
  width: 100%;
}
.checkout .summary-addon--type-isolated .summary__content {
  display: flex;
  align-items: center;
}
.checkout .addon-add__button {
  color: #764593;
  display: flex;
  font-weight: 500;
  align-content: center;
  line-height: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translate3d(0, -50%, 0);
}
.checkout .addon-add__button > span {
  margin-right: 0.6rem;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #764593;
  color: var(--color-white);
  line-height: 1;
  font-size: 14px;
  position: relative;
}
.checkout .addon-add__button > span > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 12px;
}
.checkout .summary-addon-price {
  font-size: 14px;
  margin: 0 5px;
  font-weight: 600;
}
.checkout .summary-addon-price__deducted {
  color: #f64f64;
}
.checkout .summary-addon-price.summary-discount__badge {
  background-color: #f64f64;
  color: var(--color-white);
  font-size: 12px;
  margin: 0 0.25rem;
  font-weight: 600;
  padding: 8px 12px;
  line-height: 1;
}

@media screen and (max-width: 576px) {
  .checkout .summary-addon {
    display: block;
    padding: 22px;
  }

  .checkout .addon-add__button {
    position: relative;
    margin-left: 22px !important;
  }

  .checkout .summary__icon {
    margin-bottom: 12px;
  }

  .checkout .summary {
    display: block;
  }

  .checkout .order-section .summary-addon {
    padding: 22px !important;
  }

  .checkout .summary__title {
    margin-bottom: 2px;
  }

  .checkout .summary__description {
    margin-bottom: 10px;
  }

  .checkout .summary-holder .summary__description {
    margin-bottom: 2px;
  }

  .checkout .summary__price {
    margin-bottom: auto;
  }

  .checkout .total {
    display: block;
  }

  .checkout .order-total__price {
    display: block;
    text-align: left;
  }

  .checkout .summary-addon--type-isolated .summary__content {
    display: block;
  }

  .checkout .summary-addon--type-isolated .summary__description {
    margin: 4px 0 10px 0;
  }

  .checkout .summary-addon--type-isolated .summary__content .quantity-picker {
    margin-top: 10px;
  }
}

.checkout .pricing-plan {
  padding: 12px 12px 18px;
  background: var(--color-white);
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.05),
    0 1px 3px 0 rgba(56, 60, 67, 0.15);
  transition-duration: 0.25s;
  cursor: pointer;
  border: 2px solid transparent;
  position: relative;
  width: 100%;
  margin: 0 8px;
  border-top-width: 6px;
  cursor: not-allowed !important; /*DEPRECATE*/
  filter: grayscale(100%); /*DEPRECATE*/
}
/* .checkout .pricing-plan:hover {
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.07),
    0 8px 14px 1px rgba(56, 60, 67, 0.15);
} */  /*DEPRECATE*/
.checkout .pricing-plan.active {
  border-color: rgba(127, 63, 152, 1);
}
.checkout .pricing-plan .payment-method-card__radio {
  width: 18px;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 20px;
  left: 20px;
  min-width: 20px;
  min-height: 20px;
}
.checkout .pricing-plan.active .payment-method-card__radio {
  background-color: rgba(127, 63, 152, 1);
  border-color: rgba(127, 63, 152, 1);
}
.checkout .pricing-plan.best-deal::before {
  content: 'Best deal!';
  border-radius: 30px;
  background-color: rgba(127, 63, 152, 1);
  top: 0;
  left: 50%;
  color: white;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  font-size: 0.9rem;
  font-weight: 600;
  padding: 4px 24px;
}
.checkout .pricing-plan__title {
  margin: 0;
  padding: 0;
  color: rgba(127, 63, 152, 1);
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 20px;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.checkout .pricing-plan__price {
  font-weight: 600;
  font-size: 46px;
  margin-top: 5px;
  margin-bottom: 17px;
  display: inline-block;
}
.checkout .pricing-plan__price sub {
  font-size: 1rem;
}

.checkout .pricing-plan__price sup {
  font-size: 1.5rem;
}

.checkout .pricing-plan__discount-original {
  font-size: 12px !important;
}
.checkout .pricing-plan__discount-label {
  font-size: 10px !important;
  margin-bottom: 16px !important;
}
.checkout .pricing-plan:not(.best-deal) .pricing-plan__discount-label {
  background-color: #e8e8e9 !important;
  color: #000 !important;
}
.checkout .pricing-plan__discount-biyear {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 500;
  margin-left: auto;
}
.checkout .payment-plans-info {
  margin-top: 26px;
  color: #8a8c91;
  font-size: 1rem;
}
.checkout .payment-plans-info a {
  color: rgba(127, 63, 152, 1);
}
.checkout .payment-plans-info div {
  margin-bottom: 12px;
}
.checkout .payment-plan__button {
  background-color: transparent !important;
  display: inline-block !important;
  margin-top: 4px !important;
}

@media screen and (max-width: 576px) {
  .checkout .pricing-plan__title {
    margin-top: 0;
    text-align: left;
    margin-bottom: 0;
  }

  .checkout .pricing-plan-select--row {
    text-align: left;
  }

  .checkout .pricing-plan__discount-biyear {
    text-align: left;
    margin-bottom: 0;
  }

  .checkout .summary-addon-price.summary-discount__badge {
    right: 12px !important;
    top: 12px !important;
    position: absolute;
  }

  .checkout
    .summary-addon.summary-addon--type-isolated
    .summary-addon-price.summary-discount__badge {
    top: 2px !important;
    right: -16px !important;
  }

  .checkout .pricing-plan {
    padding: 12px 48px;
    margin: 0 0 1.5rem;
  }

  .checkout .pricing-plan:last-child {
    margin-bottom: 0;
  }

  .checkout .pricing-plan .payment-method-card__radio {
    top: 24px;
    left: 24px;
  }

  .checkout .pricing-plan__price {
    font-size: 2.5rem;
    margin: 0 0 0.5rem 0;
  }

  .checkout .pricing-plan__price sup {
    font-size: 1.5rem;
    top: -0.75rem;
  }

  .checkout .pricing-plan__price sub {
    font-size: 1.5rem;
    bottom: 0.05rem;
  }

  .checkout .step-one-team__title {
    margin-bottom: 12px;
  }
}

/*
* Note: Dashboard redesign overrides
*/

.checkout-page .container {
  width: 100%;
}

.checkout .payment-plans-wrapper {
  margin-left: 0;
  margin-right: 0;
}

.checkout .step-one-team__options {
  margin-left: 0;
  margin-right: 0;
}

.checkout .step-one__team {
  margin: 0 auto;
}

.body--type-checkout .page {
  grid-template-areas: 'header' 'content';
  grid-template-columns: 1fr;
}

.checkout .step-one-team__title {
  line-height: 1.4;
  margin-top: 1rem;
}

.checkout .step-one-team__name {
  line-height: 1;
}

.checkout .pricing-plan__price {
  margin: 1rem 0 0.5rem 0;
}

.checkout-page .container {
  padding: 0;
}

.checkout .billing-description:last-of-type {
  margin-bottom: 0;
}

.checkout .ant-row {
  display: flex;
  flex-flow: row wrap;
}

.checkout .ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.checkout .ant-col-24 {
  flex: 0 0 100%;
  max-width: 100%;
}
.checkout .ant-col-xs-24 {
  flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .checkout .ant-col-sm-6 {
    display: block;
    flex: 0 0 calc(6 / 24 * 100%);
    max-width: calc(6 / 24 * 100%);
  }
  .checkout .ant-col-sm-8 {
    display: block;
    flex: 0 0 calc(8 / 24 * 100%);
    max-width: calc(8 / 24 * 100%);
  }
  .checkout .ant-col-sm-12 {
    display: block;
    flex: 0 0 calc(12 / 24 * 100%);
    max-width: calc(12 / 24 * 100%);
  }
  .checkout .ant-col-sm-16 {
    display: block;
    flex: 0 0 calc(16 / 24 * 100%);
    max-width: calc(16 / 24 * 100%);
  }
}

.checkout .payment-method__cards {
  top: 0;
}
@media screen and (min-width: 576px) {
  .payment-form {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .checkout .quantity-picker {
    align-items: center !important;
  }

  .checkout .quantity-picker > input {
    margin: 0 12px !important;
  }
}

.checkout-button.checkout-button--type-paypal > img {
  margin-left: 4px;
}

.checkout .payment-information-saved__field {
  font-family: inherit;
}
.ant-row {
  margin: 0 -8px;
}

.ant-row > .ant-col {
  padding: 0 8px;
}

.checkout .addon-picker-footer {
  z-index: 1;
}

.checkout .addon-picker-footer.is-sticky {
  margin-bottom: 0;
  padding: 1.25rem;
  left: 0;
  right: 0;
  position: fixed;
  background: #fbfafc;
}

@media screen and (min-width: 992px) {
  .checkout .addon-picker-footer.is-sticky {
    padding: 2rem;
  }
}

@media screen and (min-width: 1920px) {
  .checkout .addon-picker-footer.is-sticky {
    position: sticky;
    padding: 2rem 0;
  }
}

.checkout .step-one-team__title {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.checkout .step-one-team__title + h4 {
  font-weight: 400;
}

.checkout .summary__disabled-link {
  appearance: none;
  background: none;
  outline: none;
  border: none;
  font-size: inherit;
  font-size: 11px;
  font-weight: inherit;
  cursor: pointer;
  padding: 0;
  display: inline;
}

.checkout .summary__title {
  margin-top: 0;
}

.checkout .coupon-badge {
  appearance: none;
  border: 0;
  display: inline-flex;
  cursor: pointer;
}

#three-ds-flow {
  /* height: 50vh; */
  width: 50vw;
  min-height: 350px;
  min-width: 250px;
  margin: auto;
  margin-top: 50px;
}
