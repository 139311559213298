.checkout .addon-picker-footer__right {
  text-align: right;
}
.checkout .addon-picker-footer .checkout-button {
  margin-bottom: 8px;
  margin-top: 0;
}
.checkout .addon-picker-footer {
  margin-top: 24px;
  margin-bottom: 24px;
}
.checkout .addon-picker-footer::before {
  position: absolute;
  left: -100vw;
  right: -100vw;
  top: 0;
  bottom: 0;
  background-color: #FBFAFC;
  content: '';
}
.checkout .addon-picker-footer .ant-row {
  display: flex;
  align-items: flex-start;
}
.checkout .addon-picker-footer__right > div {
  display: inline-block;
}

.checkout .addon-picker-footer__right > div .checkout-button + div {
  font-size: 12px;
}

.checkout .addon-picker-footer.is-sticky::before {
  box-shadow: 0 0 0 1px rgb(56 60 67 / 7%), 0 3px 6px 0 rgb(56 60 67 / 15%);
  background-color: white;
}

@media screen and (max-width: 576px) {
  .checkout .addon-picker-footer {

  }
}

