/*
* TODO:
* WRITE ME!
*/

.questionmark-tooltip-icon {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

@media screen and (min-width: 1400px) {
  .questionmark-tooltip-icon {
    height: 20px;
    width: 20px;
  }
}
