.connection-type {
  margin-right: 0.75rem;
  border-radius: var(--size-border-radius);
  background-color: var(--color-bright-gray);
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5rem;
  padding: 0.03rem 1rem;
}

.connection-description {
  margin: 0.875rem 0;
  color: var(--color-text-grey);
  min-height: 16px;
  font-weight: 500;
}

.new-connection-form {
  width: 100%;
  max-width: 48rem;
}

.new-connection .button-row {
  justify-content: flex-start;
  margin-top: 20px;
}

.connection .card-header {
  margin-bottom: 0;
}

.connection-error {
  background-color: black;
  color: white;
  padding: 5px;
}
