/*
* TODO:
* WRITE ME!
*/

.page {
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    'header'
    'content';
  grid-template-rows: 5.125rem auto;
  grid-template-columns: 1fr;
  position: relative;
}

@media screen and (min-width: 992px) {
  .page {
    display: grid;
    grid-template-areas:
      'header header'
      'sidebar  content';
    grid-template-rows: 5rem 1fr;
    grid-template-columns: var(--size-sidebar) 1fr;
  }
}

.main {
  grid-area: content;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.page-content {
  padding: 1.25rem;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.page-group {
  width: 100%;
  margin-bottom: 1rem;
}

.page-heading {
  display: block;
}

.page-heading.separator {
  padding-bottom: 10px;
  border-bottom: 3px solid var(--color-border-grey);
}

.page-heading.level-1 {
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  line-height: 1.2;
}

.page-heading.level-2 {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.3;
  margin-bottom: 0.6875rem;
}

.page-heading.level-3 {
  color: var(--color-old-lavender);
  font-size: 0.8125rem;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 1rem;
}

.page-heading > span {
  display: flex;
  align-items: center;
  flex: 1;
}

.page-heading__arrow {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.page-heading__arrow > svg {
  transition: all 0.2s ease-in-out;
}

.page-heading svg {
  max-width: 0.75rem;
  margin-right: 0.5rem;
  fill: var(--color-deep-amethyst);
  transform: translateY(1px);
}

.page-heading svg:hover {
  fill: var(--color-purple);
}

.page-ilustration {
  height: 100%;
  width: 100%;
  margin: 20px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-heading__content {
  width: 100%;
}

.page-ilustration > .title {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
}

.page-ilustration > .subtitle {
  text-align: center;
  color: var(--color-text-grey);
  margin-top: 20px;
  margin-bottom: 30px;
}

.page-ilustration > img {
  width: 100%;
  margin-top: 50px;
}

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-list > img {
  margin: 30px 0;
  width: 80%;
}

.empty-list__message {
  color: var(--color-text-grey);
}

.see-all {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  font-size: 1.2rem;
  font-weight: 500;

  cursor: pointer;
  min-height: 4rem;
  transition: all 0.2s ease-in-out;
}

.see-all:hover {
  opacity: 0.8;
}

.page-heading--has-divider {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-border-grey);
  margin-bottom: 1.5rem;
}

.page-group:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 450px) {
  .page-ilustration > .title {
    font-size: 1.8rem;
  }

  .page-ilustration > img {
    width: 70%;
  }

  .empty-list > img {
    width: 260px;
  }

  .empty-list > .create-options-row {
    justify-content: space-between;
    width: 400px;
  }

  .empty-list .create-option {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 600px) {
  .page-ilustration > .subtitle {
    font-size: 0.8rem;
    width: 60%;
    margin-top: 15px;
  }

  .page-ilustration > img {
    width: 50%;
  }

  .page-heading {
    display: flex;
  }

  .page-group {
    margin-bottom: 3rem;
  }

  .page-heading {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .page-ilustration {
    width: 90%;
    margin: 50px auto;
  }

  .page-ilustration > img {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1400px) {
  .page-content {
    padding: 2rem;
  }

  .page-heading {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .page-ilustration > img {
    max-width: 400px;
  }
}

@media screen and (min-width: 992px) {
  .sidebar {
  }

  .sidebar::before {
    content: '';
  }

  .page-content {
    padding: 2rem;
  }
}

@media screen and (min-width: 1920px) {
  html {
    background-color: var(--color-anti-flash-white);
  }

  #root {
    padding: 0 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page {
    max-width: 1400px;
    width: 100%;
    background: var(--color-white);
    min-height: auto;
    height: 100vh;
    overflow: hidden;
  }
}

.page--is-templates {
  display: flex;
  flex-direction: column;
}

/* .page--is-team .table-wrapper {
  max-height: calc(100vh - var(--size-header) - 30rem);
} */

.page--is-templates .table-wrapper {
  max-height: calc(100vh - var(--size-header) - 34rem);
}

/* .page--is-team .table-wrapper {
  max-height: calc(100vh - var(--size-header) - 32rem);
} */

.page--is-payment-history .table-wrapper {
  max-height: calc(100vh - var(--size-header) - 15rem);
}

@media screen and (min-width: 554px) {
  /* .page--is-payment-history .table-wrapper {
    max-height: calc(100vh - var(--size-header) - 15rem);
  } */
}

@media screen and (min-width: 600px) {
  .page--is-templates .table-wrapper {
    max-height: calc(100vh - var(--size-header) - 24rem);
  }

  /*  .page--is-team .table-wrapper {
    max-height: calc(100vh - var(--size-header) - 29rem);
  } */
}

@media screen and (min-width: 992px) {
  .page--is-templates .table-wrapper {
    max-height: calc(100vh - var(--size-header) - 24rem);
  }

  /*   .page--is-team .table-wrapper {
    max-height: calc(100vh - var(--size-header) - 30rem);
  } */
}

@media screen and (min-width: 1200px) {
  /*   .page--is-team .table-wrapper {
    max-height: calc(100vh - var(--size-header) - 23rem);
  } */
}

/* Lower than iPhone 11 Pro Max */
@media screen and (max-height: 896px) {
  .page--is-templates .table-wrapper {
    min-height: 20rem;
  }
}
