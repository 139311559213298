/*
* TODO:
* can current reset help us?
*/

*{
    box-sizing: border-box;
    scrollbar-width: thin;
}

body {
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}