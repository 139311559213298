/*
* TODO:
* WRITE ME!
*/

/* SEARCH */
.search-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;
}

.search-wrapper > svg {
    height: 1rem;
    width: 1rem;
    fill: var(--color-text-grey);

    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.search {
    width: 100%;
    padding-left: 2.25rem;
}
/*
.search {
    width: 100%;
    padding: 0.625rem 0.75rem;
    padding-left: 2rem;
    font-size: 0.75rem;
    border-radius: var(--size-border-radius);
    border: 2px solid var(--color-border-grey);
    outline: none;
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1rem;
    font-family: var(--font-primary-family);
}

.search:hover,
.search:active,
.search:focus {
    border-color: var(--color-border-grey-hover);
}
*/

@media screen and (min-width: 600px) {
    .search-wrapper {
        max-width: 18rem;
        margin-right: 1rem;
    }
}