/*
* TODO:
* WRITE ME!
*/

.form-wrapper {
  width: 100%;
  padding: 30px 40px;
  /*
  border-radius: 10px;
  border: 3px solid var(--color-border-grey);

  background-color: white;
  box-shadow: 5px 3px 15px 0px var(--color-border-grey);
  */

  border: 1px solid var(--color-border-grey);
  border-radius: calc(var(--size-border-radius) * 3);
  background-color: var(--color-white);
  box-shadow: 0 3px 3px 0 var(--color-anti-flash-white);
}

.new-container-confirm-row {
  padding-top: 1.5rem;
}

.new-container-confirm-row .input-wrapper {
  margin-bottom: 0;
  max-width: 18rem;
}

.new-container-actions {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  margin-top: 1rem;
}

.new-container-actions > .button:first-child {
  margin-right: 10px;
}

.new-container-actions > .button:last-child {
  min-width: 5.6875rem;
}

@media screen and (min-width: 600px) {
  .new-container-confirm-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .new-container-actions {
    margin-left: 1rem;
    margin-top: 0;
  }

  .new-container-actions > .button {
    height: 2.5rem;
  }
}
