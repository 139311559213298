.custom-template-form {
  width: 100%;
  max-width: 700px;
}

.custom-template-form .input-wrapper .table-wrapper .table-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.custom-template-form .input-wrapper .table-wrapper {
  max-height: calc(100vh - var(--size-header) - 31rem);
}

@media screen and (min-width: 500px) {
  .custom-template-form .input-wrapper .table-wrapper {
    max-height: calc(100vh - var(--size-header) - 25rem);
  }
}

@media screen and (min-width: 600px) {
  .custom-template-form .input-wrapper .table-wrapper {
    max-height: calc(100vh - var(--size-header) - 24rem);
  }
}

  /*
  .containers-table {
    width: 100%;
  }

  .table-rows {
    display: block;
    max-height: 40vh;
    overflow-y: auto;

    margin-bottom: 10px;
  }

  .containers-table .table-rows {
    max-height: 25vh;
  }

  .containers-table-header,
  .container-table-row {
    display: grid;
    grid-template-columns: 0.5fr 5fr 4.5fr;
    padding: 10px 0;
  }

  .containers-table .column {
    width: auto;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-table-row.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  */
/**/

.templates-table {
  width: 100%;
}

.templates-table-header,
.template-table-row {
  display: grid;
  grid-template-columns: 0.5fr 2fr 3fr 4fr 2fr 1fr;
  padding: 10px 0;
}

.templates-table .column {
  width: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.template-table-row,
.container-table-row {
  border-bottom: 1px solid var(--color-border-grey);
  color: var(--color-text-grey);

  cursor: pointer;
}

.template-table-row img {
  height: 30px;
  width: 30px;
}

.template-table-row svg {
  fill: var(--color-purple);
  height: 20px;
  width: 20px;

  cursor: pointer;
}

.template-table-row svg:hover {
  fill: var(--color-dark-purple);
}

.create-container-confirm-row {
  display: block;
}

.create-container-confirm-row .tooltip-wrapper > .tooltip {
  width: 17rem;
  text-align: center;
}
