/*
* TODO:
* WRITE ME!
*/

/*
* This class is being attached on <body> element
* upon modal rendering, prevents background scrolling.
*
* Contains weird trick which captures scrollTop position.
*/
.modal--is-visible,
.modal--is-visible .page-content {
  overflow-y: hidden;
}

.modal {
  border: 1px solid var(--color-snow);
  border-radius: 10px;
  background-color: var(--color-white);
  box-shadow: 4px 6px 16px 0 rgba(144, 119, 153, 0.17);
  width: 100%;
  z-index: 6;
  position: fixed;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: calc(100vw - 2.5rem);
  top: 50%;
}

.modal__backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(23, 22, 23, 0.31);
  z-index: 5;
  padding: 16px;
}

.modal__header {
  border-bottom: 1px solid var(--color-snow);
  padding: 24px 32px;
  padding-right: 48px;
  line-height: 1;
  font-size: 1.1rem;
  font-weight: 600;
}

.modal__title {
  color: var(--color-dark-purple);
  font-size: 22px;
  font-weight: 600;
}

.modal__close {
  position: absolute;
  cursor: pointer;
  padding: 24px;
  fill: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
}

.modal__close > svg {
  max-width: 16px;
  width: 100%;
}

.modal__body {
  padding: 32px 0;
}

.modal__content--has-html h2 {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.modal__content--has-html p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.modal__content--has-html blockquote {
  margin: 1rem 0.5rem;
}

.modal__content--has-html p + ul {
  margin: -0.5rem 1rem 1rem 1rem;
  list-style: disc;
}

.modal__content {
  /*
    * 64px              - footer height
    * 64px (32px * 2)   - vertical padding
    * 32px (16px * 2)   - vertical margin
    * 72px              - header height
    * = 232px
    */
  max-height: calc(100vh - 232px);
  padding: 0 32px;
  overflow-y: auto;
}

/*
* If either header or footer (or both) are not present,
* following declaration handles proper vertical spacing.
*/
.modal__header + .modal__body,
.modal__body + .modal__footer {
  margin-top: -8px;
}

.modal__footer {
  padding: 24px 32px;
  border-radius: 0 0 10px 10px;
  background-color: var(--color-snow);
  line-height: 1;
}

.modal--has-animation .modal {
  animation: modal-animation 0.2s ease-in-out;
}

.modal--has-animation .modal__backdrop {
  animation: modal-backdrop-animation 0.2s ease-in-out;
}

.button-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.modal .button-row {
  margin-top: 0.625rem;
}

.modal .modal__footer .button-row {
  margin-top: 0;
}

.collaborators-modal-body .button-row {
  margin-top: 30px;
}

.button-row .button:last-child {
  margin-left: 20px;
}

.qr-modal-body .content {
  display: flex;
}

.qr-modal-body .content .input-wrapper {
  margin-left: 50px;
}

.qr-modal-footer {
  display: flex;
  flex-direction: column;
}

.qr-modal-footer .help {
  font-size: 0.7rem;
  color: var(--color-text-grey);
  margin-bottom: 8px;
}

.qr-modal-footer .code {
  font-weight: 500;
}

.button-row.bottom-margin {
  margin-top: 20px;
}

.modal-warning-message {
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--color-text-grey);
  margin-bottom: 25px;
}

@keyframes modal-animation {
  0% {
    transform: translate3d(-50%, -100%, 0);
  }
  100% {
    transform: translate3d(-50%, -50%, 0);
  }
}

@keyframes modal-backdrop-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 600px) {
  .modal {
    max-width: 36rem;
  }
}

.expired-modal {
  top: calc(var(--size-header) - 2px);
  z-index: 2;
}

.expired-modal + .modal {
  z-index: 3;
  top: calc(50% + calc(var(--size-header) / 2));
}

.banner--is-visible .expired-modal {
  top: calc(var(--size-header) + 60px);
}

@media screen and (min-width: 600px) {
  .banner--is-visible .expired-modal {
    top: calc(var(--size-header) + 44px);
  }
}

@media screen and (min-width: 992px) {
  .expired-modal {
    position: fixed;
    left: var(--size-sidebar);
    top: calc(var(--size-header) + 2px);
  }

  .expired-modal + .modal {
    position: fixed;
    left: calc(50% + var(--size-sidebar) / 2);
  }

  .banner--is-visible .expired-modal {
    top: calc(var(--size-header) + 48px);
  }
}

@media screen and (min-width: 1920px) {
  .modal--is-visible,
  .modal--is-visible .page-content {
    overflow-y: unset;
  }

  .modal--is-visible .main {
    overflow-y: hidden;
  }

  .expired-modal {
    position: absolute;
  }
}
