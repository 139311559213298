.domains-selected-container {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 1;
}

.domains-help {
  color: var(--color-text-grey);
  font-size: 0.8125rem;
  margin-bottom: 2rem;
  line-height: 1;
}

.domains-help + .input-label {
  color: var(--color-text-grey);
  font-size: 0.8125rem;
  margin-bottom: 0.625rem;
  line-height: 1;
  display: block;
}

.link {
  color: var(--color-purple);
  text-decoration: underline;
}

.domains-table {
  width: 100%;
}

.containers-table .column {
  width: auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .domains-table-header,
.domain-table-row {
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 2.5fr 0.5fr;
  padding: 10px;
  padding-right: 20px;
}

.domain-table-row,
.no-domains {
  border-bottom: 1px solid var(--color-border-grey);
  color: var(--color-text-grey);
}

.domain-table-row svg {
  fill: var(--color-purple);
  height: 20px;
  width: 20px;

  cursor: pointer;
} */

.table--is-domains svg {
  fill: var(--color-purple);
  height: 20px;
  width: 20px;

  cursor: pointer;
}

.no-domains {
  width: 100%;
  padding: 10px;
  font-size: 0.7rem;

  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  border-bottom: 0;
  padding: 2rem 0;
}
