/*
* TODO:
* WRITE ME!
*/

.container-status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 0.625rem;
  cursor: pointer;
  transform: translateY(25%);
}

.container-status__tooltip span {
  text-transform: uppercase;
  font-weight: 600;
}

.container-status-icon {
  height: 0.5rem;
  width: 0.5rem;
  position: relative;
  border-radius: 50%;
  transform: translateY(-2px);
}

.container-status-icon::before {
  content: '';
  background-color: inherit;
  opacity: 0.2;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/*
* TODO: !!!!!!
* ovo ispod definitivno još jednom promislit
*/
.container-status-icon--type-green {
  background-color: var(--color-green);
}

.container-status-icon--type-red {
  background-color: var(--color-red);
}

.container-status-icon--type-orange {
  background-color: var(--color-orange);
}

.container-status-icon--type-purple {
  background-color: var(--color-purple);
}

.container-status__tooltip--color-green {
  color: var(--color-green);
}

.container-status__tooltip--color-red {
  color: var(--color-red);
}

.container-status__tooltip--color-orange {
  color: var(--color-orange);
}

.container-status__tooltip--color-purple {
  color: var(--color-purple);
}

.container-always-on {
  display: flex;
}

.always-on {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
}

.always-on .tooltip-wrapper {
  transform: translateY(2px);
}

.always-on .tooltip-wrapper img {
  margin-bottom: 1px;
}

.container-info {
  display: flex;
  align-items: center;
  margin: 0.875rem 0;
  padding-top: 0.875rem;
  border-top: 1px solid var(--color-border-grey);
}

.container-resources {
  display: flex;
  align-items: center;
}

.container-resources + * {
  margin-left: auto;
}

.container-resource {
  margin-right: 0.625rem;
  color: var(--color-old-lavender);
  display: flex;
  align-items: center;
}

.container-resource > svg {
  height: 1.1rem;
  fill: var(--color-old-lavender);
  width: auto;
  margin-right: 0.375rem;
}

.container-card-template {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  margin-left: auto;
}

.template-card-image {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
}

.template-row-image {
  width: 2.5rem;
  height: auto;
  object-fit: contain;
  margin-left: auto;
}

.container-stack-name {
  color: var(--color-old-lavender);
  font-size: 1rem;
  margin-right: 1rem;
}

.collaborators {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collaborators .tooltip-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collaborators svg {
  font-size: 0.8125rem;
  letter-spacing: 0;
}

.collaborators svg + span {
  padding-left: 0.375rem;
  color: var(--color-text-dark-grey);
  font-weight: 500;
}

.toggle-switch + span {
  margin-left: 0.625rem;
  margin-right: 0.375rem;
}

.filters {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 0;
}

.filter {
  color: var(--color-dark-purple);
  font-size: 0.8125rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: var(--size-border-radius);
  padding: 0.75rem;
  background-color: var(--color-card-grey);
  transition: all 0.2s ease-in-out;
}

.filter {
  margin-bottom: 0.5rem;
}

.filter:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 600px) {
  .filters {
    display: inline-block;
    margin: 0;
    margin-bottom: 0.25rem;
  }

  .filter {
    margin-bottom: 0rem;
    margin-right: 0.5rem;
  }

  .filter:last-child {
    margin-right: 0;
  }
}

.filter:hover {
  background-color: var(--color-light-purple);
}

.filter--is-active {
  color: var(--color-primary);
  text-decoration: underline;
  background-color: var(--color-light-purple);
}

.shared-tag {
  color: var(--color-green);
  background-color: var(--color-light-green);

  font-weight: 500;
  font-size: 0.7rem;

  padding: 5px;
  margin-right: 5px;
  border-radius: var(--size-border-radius);
}

.share-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.875rem;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid var(--color-border-grey);
}

.share-owner {
  display: flex;
  align-items: center;
}

.share-owner img {
  width: 1.1875rem;
  margin-right: 0.5rem;
}

.share-owner .owner {
  color: var(--color-text-grey);
  font-weight: 500;
}

.collaborators-modal-body {
  display: flex;
  flex-direction: column;
}

.invite-collaborator {
  display: flex;
  align-items: flex-end;
}

.invite-collaborator .button {
  margin-left: 15px;
  margin-bottom: 20px;
  min-width: auto;
  height: 40px;
}

.collaborators-title {
  font-size: 0.7rem;
  color: var(--color-text-grey);
}

.no-collaborators {
  font-size: 0.7rem;
}

.collaborators-list {
  display: grid;
  column-gap: 5px;

  margin-top: 10px;
  padding-right: 10px;
  max-height: 250px;
  overflow-y: auto;
}

.collaborator-component {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  padding: 10px;
  margin-bottom: 10px;

  border-radius: 30px;
}

.collaborator-component .options-menu {
  top: auto;
  bottom: 1rem;
}

.collaborator-component:last-child {
  margin-bottom: 0;
}

.collaborator-info {
  display: flex;
  align-items: center;
}

.collaborator-info > .profile-avatar {
  width: 40px;
  margin-right: 15px;
}

.collaborator-identifiers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 80%;
}

.collaborator-identifiers.pending {
  justify-content: center;
}

.collaborator-identifiers .name {
  font-weight: 600;
}

.collaborator-identifiers .email {
  color: var(--color-text-grey);
}

.collaborator-role {
  border: 1px solid black;
  border-radius: 5px;

  font-size: 0.65rem;
  font-weight: 500;
  padding: 3px 10px;

  text-transform: uppercase;
  margin-left: auto;
}

.collaborator-role.owner {
  border-color: var(--color-dark-green);
  color: var(--color-green);
}

.collaborator-role.collaborator {
  border-color: var(--color-purple);
  color: var(--color-purple);
}

.collaborator-role.pending {
  border-color: var(--color-orange);
  color: var(--color-orange);
}

.collaborator-role.protected {
  border-color: var(--color-red);
  color: var(--color-red);
}

.remove-collaborator:hover {
  opacity: 1;
}

.remove-collaborator {
  cursor: pointer;
  height: 21px;
  width: 21px;
  margin-left: 1em;
  fill: var(--color-text-grey);
  opacity: 0.6;
}

@media screen and (min-width: 1400px) {
  .always-on {
    width: 155px;
  }
}
