/*
* TODO:
* - missing heading and title definitions (!?)
* - <p> styles definition?
* - font pixelate on windows, antialiasing
*/

body {
    font-family: var(--font-primary-family);
    font-size: calc(var(--size-font-base) * 0.815); /* 16*0.815=13 */
    -webkit-font-smoothing: antialiased;
    line-height: 1rem; /*16px*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: var(--color-dark-purple);
}
