.footer {
  margin-top: auto;
  padding: 0.5rem 2rem 0.5rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--color-white);
}

.footer__link {
  color: var(--color-old-lavender);
  padding: 0 1rem;
  flex-basis: 50%;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}

.footer__link:hover {
  opacity: 0.6;
}

@media screen and (min-width: 600px) {
  .footer {
    display: block;
    padding-bottom: 1.75rem;
  }
}

@media screen and (min-width: 992px) {
  .footer {
    padding: 2rem;
    margin-top: -1rem;
  }

  .footer__link {
    margin-bottom: 0;
  }

  .footer__link:last-child {
    padding-right: 0;
  }
}
