.pulse-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  z-index: 7;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.pulse {
  width: 12rem;
  animation: pulse 1.25s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
