/*
* TODO:
* WRITE ME!
*/

.create__options {
}

.create__option {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.625rem;
  padding: 0.5rem 1.5rem;
  border-radius: var(--size-border-radius);
  border: 1px solid var(--color-border-grey);
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  color: var(--color-old-lavender);
  letter-spacing: 0;
  margin-right: 1rem;
  transition: all 0.2s ease-in-out;
}

.create__option:hover {
  box-shadow: 5px 5px 15px 2px var(--color-border-grey);
}

.create__option > svg {
  width: auto;
  height: 1.25rem;
  fill: var(--color-text-grey);
  margin-right: 0.625rem;
}

.create__option:last-child {
  margin-right: 0;
}

/*
underneath is old code
*/
/*
.create-options-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.create-options-row.providers {
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}


.create-options-row > .create-option:last-child {
  margin-bottom: 0px;
}

.create-option:hover {
  box-shadow: 5px 5px 15px 2px var(--color-border-grey);
}

.create__option > svg {
  height: 20px;
  width: 20px;
  fill: var(--color-text-grey);

  margin-right: 10px;
}

@media screen and (min-width: 440px) {
  .create-options-row.providers .create__option {
    width: 120px;
    margin-bottom: 0px;
    margin-right: 10px;
    justify-content: space-evenly;
  }

  .create-options-row.providers .create-option:last-child {
    margin-right: 0px;
  }
}

@media screen and (min-width: 550px) {
  .create-options-row {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .create__option {
    margin-bottom: 15px;
  }

  .create-options-row > .create-option:last-child,
  .create-options-row.containers-page > .create__option {
    margin-bottom: 0;
  }

  .create__option > svg {
    margin-left: 0px;
  }
}

@media screen and (min-width: 750px) {
  .create-options-row {
    width: 90%;
  }

  .create-options-row.containers-page {
    justify-content: flex-start;
  }

  .create__option {
    margin-bottom: 0;
    font-size: 0.8rem;
  }

  .create-options-row.containers-page > .create-option:first-child {
    margin-right: 15px;
  }
}

@media screen and (min-width: 992px) {
  .create__option {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1000px) {
  .create__option {
    justify-content: space-evenly;
    margin-bottom: 0;
  }
}

*/
