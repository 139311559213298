.checkout .button--trial {
  text-align: center;
}

.checkout .button--trial a {
  color: rgba(127, 63, 152, 1);
  background-color: transparent !important;
  display: inline-block !important;
  margin: 44px 0 22px 0 !important;
}

.checkout .step1__option-separator {
  text-align: center;
  color: #8a8c91;
  font-size: 1rem;
}
