/*
* TODO:
* WRITE ME!
*/

.account-section {
  padding-bottom: 1.875rem;
  border-bottom: 1px solid var(--color-border-grey);
  margin-bottom: 1rem;
}

.account-section:last-child {
  margin-bottom: 0px;
  border-bottom: 0;
}

.account-id {
  font-weight: initial;
  font-size: 0.7rem;
  color: var(--color-text-grey);
  margin-left: 10px;
  margin-top: 6px;
  position: absolute;
  left: 7rem;
}

@media screen and (min-width: 992px) {
  .account-id {
    left: 8rem;
  }
}

.personal-info-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

form.update-personal-info,
form.change-password {
  width: 100%;
  max-width: 30rem;
}

form.update-personal-info {
}

.upload-profile-image {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 30px;
}

.upload-profile-image .profile-icon {
  height: 120px;
  width: 120px;
  margin-bottom: 20px;
}

.two-inputs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.update-personal.info > .input-wrapper:last-child {
  margin-bottom: 0px;
}

form.change-password {
  margin-top: 20px;
}

.account-section .page-heading.level-3 {
  margin-bottom: 20px;
}

.connected-accounts-list:not(:empty) {
  margin-top: 2rem;
  margin-bottom: -1rem;
  display: flex;
  flex-wrap: wrap;
}

.connected-account-card,
.toggle-2fa-card {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid var(--color-border-grey);
  border-radius: var(--size-border-radius);
  padding: 0.875rem 1.125rem 1rem;
}

.connected-account-card {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.provider-name {
  margin-right: 1rem;
}

.connected-account-card .provider-container {
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  font-weight: 600;
}

.connected-account-card img {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}

.disconnect-connected-account {
  font-size: 0.6rem;
  font-weight: 500;

  padding: 5px 10px;
  border-radius: 5px;

  color: var(--color-red);
  background-color: var(--color-light-red);

  cursor: pointer;
}

.toggle-2fa-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 35rem;
}

.toggle-2fa-card .info {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.toggle-2fa-card .title {
  color: var(--color-dark-purple);
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 0.1875rem;
}

.toggle-2fa-card .description {
  color: var(--color-old-lavender);
  font-size: 0.75rem;
  line-height: 1rem;
}

.toggle-2fa-card .toggle-switch {
  margin-top: 20px;
}

@media screen and (min-width: 500px) {
  .two-inputs-container {
    flex-direction: row;
  }

  .two-inputs-container.collapsed {
    width: 50%;
  }

  .two-inputs-container > .input-wrapper {
    width: calc(50% - 10px);
  }

  .two-inputs-container > .input-wrapper:first-of-type {
    margin-right: 20px;
  }

  .input-wrapper.small {
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .upload-profile-image .profile-icon {
    height: 150px;
    width: 150px;
  }
}

@media screen and (min-width: 700px) {
  .personal-info-container {
    flex-direction: row;
    align-items: flex-start;
    width: 95%;
  }

  form.update-personal-info,
  form.change-password {
    /* width: 340px; */
  }

  .upload-profile-image {
    margin-top: 0px;
  }
}

@media screen and (min-width: 992px) {
  .personal-info-container {
    width: 100%;
  }

  .upload-profile-image .profile-icon {
    height: 120px;
    width: 120px;
  }
}

@media screen and (min-width: 1000px) {
  .upload-profile-image .profile-icon {
    height: 150px;
    width: 150px;
  }
}

@media screen and (min-width: 1200px) {
  .personal-info-container {
    width: 80%;
  }
}

@media screen and (min-width: 1400px) {
  .personal-info-container {
    width: 40%;
  }

  .connected-account-card .provider-container {
    font-size: 0.8rem;
  }

  .disconnect-connected-account {
    font-size: 0.7rem;
  }
}
