@font-face {
    font-family: 'Galano-Grotesque';
    src: url('../../fonts/Galano-Grotesque/Galano-Grotesque-Regular.otf')
    format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'Galano-Grotesque';
    src: url('../../fonts/Galano-Grotesque/Galano-Grotesque-Medium.otf')
    format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'Galano-Grotesque';
    src: url('../../fonts/Galano-Grotesque/Galano-Grotesque-SemiBold.otf')
    format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: 'Galano-Grotesque';
    src: url('../../fonts/Galano-Grotesque/Galano-Grotesque-Bold.otf')
    format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'Galano-Grotesque';
    src: url('../../fonts/Galano-Grotesque/Galano-Grotesque-ExtraBold.otf')
    format('opentype');
    font-weight: 800;
}