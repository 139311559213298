/*
* TODO:
* - find a better name
 */

.copy-component {
    display: flex;
    border-radius: var(--size-border-radius);
    border: 1px solid var(--color-light-silver);
    background-color: var(--color-snow);
    align-items: stretch;
}

.copy-component .content {
    color: var(--color-old-lavender);
    padding: 0.75rem 1rem;
    word-wrap: anywhere;
}

.copy-component__button {
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
    margin-left: auto;
    box-shadow: none;
}

.button + .copy-component {
    margin-top: 1rem;
}